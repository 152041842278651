@import '../../../../../assets/colors';
@import '../../../../../assets/dimens';

.detail-success-item {
  display: flex;
  height: 64px;
  background-color: $wildSand;
  align-items: center;
  margin: 0 26px 8px 26px;

  .information {
    display: flex;
    flex: 11;
    align-items: center;

    i {
      flex: 1;
      text-align: center;

      &.check {
        background-color: rgba(0,0,0,0);
        color: $pistachio;
      }

      &.schedule {
        color: $rollingStone;
      }
    }

    .title {
      flex: 10;
    }
  }

  .edit {
    flex: 5;
    display: flex;
    align-items: center;

    .earned {
      visibility: hidden;
      width: 0;
    }

    input {
      font-size: 13px;
      font-weight: 500;
      background-color: $white;
      height: 46px;
      padding: 0 0 0 16px;
      cursor: pointer;
      color: $armadillo;

      &::-webkit-clear-button {
        display: none;
      }

      &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: $armadillo;
        opacity: 1; /* Firefox */
      }

      &:-ms-input-placeholder { /* Internet Explorer 10-11 */
        color: $armadillo;
      }

      &::-ms-input-placeholder { /* Microsoft Edge */
        color: $armadillo;
      }

      &.disabled {
        cursor: auto;
        color: $lynch;

        &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
          color: $lynch;
          opacity: 1; /* Firefox */
        }

        &:-ms-input-placeholder { /* Internet Explorer 10-11 */
          color: $lynch;
        }

        &::-ms-input-placeholder { /* Microsoft Edge */
          color: $lynch;
        }
      }
    }

    .switch-item {
      padding-right: 16px;
      flex: 1;
      text-align: right;
      justify-content: right;

      .switch-input {
        display: none;
      }

      .switch-label {
        position: relative;
        cursor: pointer;
        text-align: right;
        padding: 16px 0 16px 44px;
      }

      .switch-label:before, .switch-label:after {
        content: "";
        position: absolute;
        margin: 0;
        outline: 0;
        top: 50%;
        -ms-transform: translate(0, -50%);
        -webkit-transform: translate(0, -50%);
        transform: translate(0, -50%);
        -webkit-transition: all 0.3s ease;
        transition: all 0.3s ease;
      }

      .switch-label:before {
        left: 1px;
        width: 34px;
        height: 14px;
        background-color: rgba(34,31,31,0.26);
        border-radius: 8px;
      }

      .switch-label:after {
        left: 0;
        width: 20px;
        height: 20px;
        background-color: $seashell;
        border-radius: 50%;
        box-shadow: 0 0 1px 0 rgba(0,0,0,0.12), 0 1px 1px 0 rgba(0,0,0,0.24);
      }

      .switch-input:checked + .switch-label:before {
        background-color: rgba(0,163,224,0.5);
      }

      .switch-input:checked + .switch-label:after {
        background-color: $ceruleanLight;
        -ms-transform: translate(80%, -50%);
        -webkit-transform: translate(80%, -50%);
        transform: translate(80%, -50%);
      }

      .switch-input:checked + .switch-label .toggle--on {
        display: inline-block;
      }

      .switch-input:checked + .switch-label .toggle--off {
        display: none;
      }
    }
  }
}

@media screen and (max-width: 800px) {
  .detail-success-item {
    display: flex;
    flex-wrap: wrap;
    height: auto;
    padding: 8px;
    margin: 4px;

    .information {
      height: 72px;

      .check {
        margin-right: 8px;
      }

      .title {
        white-space: nowrap;
        
        @include width(520px) {
          position: relative;
          right: 20px;
        }
      }
    }

    .edit {
      padding-left: 16px;
      justify-content: space-around;

      input {
        margin-right: 12px;
      }

      .earned {
        visibility: visible;
        width: auto;
        margin-right: 16px;
        color: $doveGray;
        font-size: 12px;
        line-height: 12px;
      }

      .switch-item {
        flex: initial;
      }
    }
  }
}
