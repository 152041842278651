@import '../../../../../assets/colors';
@import '../../../../../assets/dimens';
.success-item {
  * {
    box-sizing: border-box;
  }

  height: 230px;
  width: 160px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 5px;
  cursor: pointer;
  background-color: $white;
  .header-content {
    height: 47%;
    width: 100% !important;
    border-radius: 5px;
    .default-header {
      height: 100%;
      width: 100% !important;
      background-color: $mystic;
      border-radius: 5px;
      position: relative;
      display: flex;
      justify-content: center;
      padding-top: 15%;
      .circle {
        width: 30px;
        height: 30px;
        border-radius: 100%;
        border: 1px solid $jungleGreen2;
        position: relative;
        top: 18%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding: 5px;
        i {
          color: $jungleGreen2;
        }
      }
    }
    img {
      width: 100% !important;
      height: 100% !important;
      object-fit: initial;
      border-radius: 5px;
      @include phone {
        object-fit: initial;
        
      }
    }
  }

  .success-detail {
    width: 100%;
    height: 55%;
    border-radius: 5px;
    background-color: $white;
    box-shadow: 0 3px 4px 0 rgba(10, 15, 44, 0.2), 0 4px 16px 0 rgba(104, 129, 151, 0.15),
      0 8px 26px 0 rgba(0, 0, 0, 0.15);
    // position: absolute;
    // left: 0;
    // bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    h3 {
      color: $codGray50;
      font-family: Ubuntu;
      font-size: 12px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 16px;
      text-align: center;
      padding: 14px 5px 0 5px;
      &.text {
        font-size: 14px;
      }
    }
    .actions {
      display: flex;
      justify-content: space-between;
      flex-wrap: nowrap;
      padding: 8px;
      position: relative;
      i {
        height: 25px;
        width: 15px;
        margin-right: 10px;
        color: $nevada;
      }
      .btn {
        i {
          color: $green-500;
          &.fill-1 {
            height: 12px;
            width: 15px;
            color: $codGray50;
          }
        }
      }
    }
  }
  .success-validated {
    width: 100%;
    height: 130px;
    border-radius: 5px;
    background-color: $white;
    box-shadow: 0 3px 4px 0 rgba(10, 15, 44, 0.2), 0 4px 16px 0 rgba(104, 129, 151, 0.15),
      0 8px 26px 0 rgba(0, 0, 0, 0.15);
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    h3 {
      color: $codGray50;
      font-family: Ubuntu;
      font-size: 12px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 16px;
      text-align: center;
      padding: 14px 5px 0 5px;
    }
    p {
      text-align: center;
      font-size: 12px;
      color: $nevada;
    }
    .actions {
      display: flex;
      justify-content: space-between;
      flex-wrap: nowrap;
      padding: 8px;
      position: relative;

      i {
        height: 25px;
        width: 15px;
        margin-right: 10px;
        color: $nevada;
      }

      .btn {
        i {
          color: $green-500;

          &.fill-1 {
            height: 12px;
            width: 15px;
            color: $codGray50;
          }
        }
      }
    }
  }
}
.success-item-offset {
  * {
    box-sizing: border-box;
  }

  height: 100%;
  width: 160px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 5px;
  cursor: pointer;
  .plus {
    width: 100%;
    height: 130px;
    border-radius: 5px;
    background-color: $white;
    box-shadow: 0 3px 4px 0 rgba(10, 15, 44, 0.2), 0 4px 16px 0 rgba(104, 129, 151, 0.15),
      0 8px 26px 0 rgba(0, 0, 0, 0.15);

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    p {
      padding: 16px;
      font-size: 14px;
      text-align: center;
    }
    svg {
      height: 32px;
      width: 32px;
      fill: $nevada;
    }
  }
}

@media screen and (max-width: 868px) {
  .success-item {
    height: 200px !important;
    width: 160px !important;
    .success-detail {
      height: 160px !important;
      border-top-left-radius: 0px;
      border-top-right-radius: 0px;
    }
    .header-content {
      height: 50%;
      width: 100% !important;
      background-color: transparent;
      border-radius: 5px;
      img {
        object-fit: inherit;
       
      }
    }
  }
}
@media screen and (max-width: 466px) {
  .desktop_successes_list {
    display: flex;
    justify-content: space-evenly;
  }
}
