 @import '../../../../assets/colors.scss';
@import "../../../../assets/dimens.scss";
 .popup-wrapperss {
   display: flex;
   justify-content: center;
   align-items: center;
   position: fixed;
   top: 0;
   right: 0;
   left: 0;
   bottom: 0;
   opacity: 0;
   background-color: $codGray60;
   transition: z-index, opacity 0.1s ease-in;
   box-sizing: border-box;

   .popupss {
     width: 890px !important;
     height: 95vh;
     display: flex;
     flex-direction: column;
     justify-content: space-between;
     padding: 16px;
     background-color: $white;
     border-radius: 5px;
     box-shadow: 0 3px 4px 0 rgba(10, 15, 44, 0.2), 0 4px 16px 0 rgba(104, 129, 151, 0.15), 0 8px 26px 0 rgba(0, 0, 0, 0.15);
     @include phone{
      width: 100%;
     }
     .close {
       width: 44px;
       height: 44px;
       align-self: flex-end;
       padding: 12px;
       font-size: 20px;
       font-weight: bold;
       color: $stormDust;
       cursor: pointer;
     }
      .action-btn {
         display: flex;
         justify-content: flex-end;
         align-items: center;
         padding: 16px 0;
         .button {
           width: 22.5%;
           @include phone {
            width: 100%;
           }
         }
       }
       //Header
       .header{
        display: flex;
        justify-content: space-between;
        align-items: center;
        .header-title{
          color: $codGray50;
          font-size: 20px;
          font-weight: bold;
          line-height: 24px;
        }
        i{
          font-size: 24px;
        }
       }
       //Content
      .succes-content{
        width: 100%;
        padding: 16px 0;
        display: flex;
        gap: 20px;
        flex-wrap: wrap;
        overflow-y: auto;
        overflow-x: hidden;
        &.center{
          justify-content: center;
        }
        &::-webkit-scrollbar {
            width: 10px;
          }
          @include phone{
            padding: 16px;
          }
      }

     }
   &.shown {
     z-index: 100;
     opacity: 1;
   }

   &.hidden {
     display: none;
     transition: none;
     z-index: -1;
   }
 }