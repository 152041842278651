@import '../../../assets/colors';
@import '../../../assets/dimens';

#planning {
  margin: 70px 50px;
  background-color: $white;
  box-shadow: 0 3px 4px 0 rgba(10, 15, 44, 0.2), 0 4px 16px 0 rgba(104, 129, 151, 0.15), 0 8px 26px 0 rgba(0, 0, 0, 0.15);
  display: flex;
  flex-direction: row;
  min-height: 605px;
  
  @include phone {
    flex-direction: column;
    margin: 30px 16px 24px 16px;
  }
  
  .calendar, .events {
    padding: 27px 0;

    @include phone {
      padding: 27px 12px;
      min-height: initial;
    }
  }

  .calendar {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 27px 23px;
    width: 425px;
    min-height: 600px;

    @include phone {
      min-height: initial;
    }


    &.add-padding {
      min-width: 425px;

      @include phone {
        min-width: initial;
      }
    }


    @include phone {
      width: initial;
    }

    .calendar-bloc {
      display: flex;
      flex-direction: column;
      position: sticky;
      top: 100px;

      @include phone {
        display: flex;
        flex-direction: column;
      }

      >.add-button {
        width: 158px;
        height: 40px;
        margin-top: 100px;

        @include phone {
          align-self: center;
          width: 40%;
          margin: 10px 0;
          position: initial;
          top: initial; 
        }

        >.material-icons {
          color: $white;
        }
      }
    }
  }
}
  
/* DayPicker */

.DayPicker {
  width: 100%;
  @include phone {
    width: 100%;
    position: initial;
  }
}

.DayPicker-wrapper {
  outline: none;

  .DayPicker-NavButton {
    right: 0;
    top: 0;
    outline: none;

    @include phone {
      right: 1.2vw;
    }
  }
}

.DayPicker-Caption  {
  @include phone {
    padding: 0 2.5%;
  }

  > div {
    text-transform: capitalize;
    font-size: 18px;
    color: $codGray;
    
    @include phone {
      font-size: 5vw;
    }
  }

}

.DayPicker-WeekdaysRow {
  display: flex;
  justify-content: space-around;
}

.DayPicker-Weekday > abbr {
  color: $black;
  font-size: 12px;

  @include phone {
    font-size: 3vw;
  }
}

.DayPicker-Body {
  display: flex;
  flex-direction: column;
}

.DayPicker-Month {
  display: flex;
  flex-direction: column;
  margin: 0;

  @include phone {
    width: 100%;
  }
}

.DayPicker-Week {
  display: flex;
  justify-content: space-around;
}

.DayPicker-Day {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 4px!important;
  width: 45px;
  height: 45px;
  border: 1px solid rgba($iron, 0.66);
  font-size: 13px;
  outline: none;
  color: $abbey;
  font-weight: 100;
  transition: background-color .5s;

  animation-name: dpdFade;
  animation-duration: .7s;

  @keyframes dpdFade {
    from {opacity: 0}
    to {opacity: 1}
  }

  &.DayPicker-Day--selected {
    background-color: $bondiBlue !important;
    color: $white !important;
    border: none;
  }

  &.DayPicker-Day--outside {
    opacity: .5;
    cursor: pointer;
  }

  @include phone {
    width: 10%;
    height: 8vw;
    margin: 0.8vw !important;
    font-size: 3vw;
  }
}

