@import "../../../../assets/colors";

.user-form {
  > p {
    color: $codGray;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 22px;
  }

  .separator {
    width: 100%;
    border-bottom: 1px dashed $alto;
    margin-top: 24px;
    margin-bottom: 24px;
  }

  > .wrapper {
    display: flex;
    flex-direction: row;
    margin-bottom: 8px;

    .civility {
      width: calc((60% - (3 * 16px)) * 0.45);
      margin-right: 16px;
    }

    .lastname {
      width: calc((60% - (3 * 16px)) * 0.55);
      margin: 0 16px;
    }

    .firstname,
    .phone {
      width: calc(40% - 16px);
      margin-left: 16px;
    }

    .email {
      width: calc(60% - 16px);
      margin-right: 16px;
    }

    &.region {
      width: calc(60% - 16px);
      margin-bottom: 0;

      .region {
        width: 100%;
      }
    }
  }

  .drop-down .box {
    height: 40px;
    background: $athensGrayDark;
    border-bottom: 2px solid $athensGrayDark;

    .current {
      color: $armadillo;
      font-size: 13px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 24px;
    }
  }

  .switch-wrapper p {
    color: $black;
  }

  .input-wrapper input {
    height: 40px;
    color: $armadillo;
    font-size: 13px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 24px;
    background: $athensGrayDark;
    border-bottom: 2px solid $athensGrayDark;
  }

  .mentor-form {
    margin-top: 8px;

    > p {
      margin-bottom: 8px;
      margin-top: 16px;
      color: $codGray;
      font-size: 16px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 22px;
    }

    > .wrapper {
      display: flex;
      flex-direction: row;
      margin-bottom: 8px;

      .text-area-wrapper {
        width: 100%;

        textarea {
          background: $athensGrayDark;
          border-bottom: 2px solid $athensGrayDark;
        }
      }

      .name {
        width: calc(50% - 16px);
        margin-right: 16px;
      }

      .address {
        width: calc(50% - 16px);
        margin-left: 16px;
      }

      .postal {
        width: calc((50% - (3 * 16px)) * 0.4);
        margin-right: 16px;
      }

      .city {
        width: calc((50% - (3 * 16px)) * 0.6);
        margin-right: 16px;
        margin-left: 16px;
      }

      .country {
        width: calc(50% - 16px);
        margin-left: 16px;
      }

      .phone,
      .firstname {
        width: calc(40% - 16px);
        margin-left: 16px;
      }

      .email,
      .lastname {
        width: calc(60% - 16px);
        margin-right: 16px;
      }
    }
  }
  .action {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-top: 24px;

    .button {
      width: 170px;
    }
  }
}

@media screen and (max-width: 768px) {

  .user-form {

    > .wrapper {
      flex-direction: column;

      .civility, .firstname, .lastname, .email, .phone, .region {
        width: 100%;
        margin: 0 0 8px 0;
      }
    }

    .mentor-form {

      > .wrapper {
        flex-direction: column;
  
        .name, .postal, .city, .country, .address, .firstname, .lastname, .email, .phone {
          width: 100%;
          margin: 0 0 8px 0;
        }
      }
    }
  }
}