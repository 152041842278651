@import "../../../assets/colors";
@import "../../../assets/dimens";

#footer {
  width: 100%;
  padding: 32px 0;
  z-index: -10;
  &.float{
    position: fixed;
    left: 0;
    bottom: 0;
    right: 0;
  }
@include paysage-phone{
  display: none !important;
}
  @include width(1180px) {
    position: initial;
  }

  > .content {
    display: flex;
    flex-direction: row;
    height: 100%;
    align-items: center;
    box-sizing: border-box;
    padding: 0 50px;
    color: $codGray;


    #link {
      display: flex;
      flex-direction: row;
      align-items: center;
      flex: 1;

      p {
        margin: 0 20px;

        &:hover {
          color: $bondiBlue;
          cursor: pointer;
        }
        &:active{
          color: $bondiBlue;
        }
      }
    }

    #go-web-epa {

      a {
        &:last-child {
          font-weight: bold;
        }
      }

      &:hover {
        color: $bondiBlue;
        cursor: pointer;
      }
      &:active{
        color: $bondiBlue;
      }
    }
  }
}

@media screen and (max-width: 800px) {
  #footer {
    height: auto;

    > .content {
      padding: 24px;
      flex-direction: column;

      #copyright {
        margin-bottom: 12px;
      }
    }
  }
}
