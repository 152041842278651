@import '../../../../assets/colors';
@import '../../../../assets/dimens';

.add-meeting {
  padding: 27px 12px 27px 0;
  width: 45%;
  position: relative;

  @include width(800px) {
    padding: .5vw 18px .5vw 18px;
    width: 100%;
  }


  >.title {
    font-size: 18px;
    margin-bottom: 57px;
    color: $codGray;
    font-weight: 500;

    @include width(800px) {
      font-size: 5vw;
      margin-bottom: 5vw;
    }
  }

  >.date {
    font-size: 24px;
    font-weight: 100;
    margin-bottom: 28px;
    color: $codGray;

    @include width(800px) {
      font-size: 4vw;
    }
  }

  >.description {
    color: $lynch;
    font-size: 12px;
    margin-bottom: 5px;
    margin-top: 79px;

    @include width(800px) {
      margin-top: initial;
    }
  }

  >.text-input {
    width: 100% !important;
    background-color: $mystic;
    border: none;
    border-radius: 2px;
    outline: none;
    height: 185px;
    padding: 16px;
    font-size: 13px;
    color: $armadillo;
    resize: none;
  }

  >.buttons {
    display: flex;
    margin-top: 19px;
    justify-content: flex-end;
    flex-wrap: wrap;

    @include width(800px) {
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
    }

    >.button {
      height: 40px;
      margin: 0 0 20px 20px;
      padding: 0 20px;

      @include width(800px) {
        width: 100%;
        margin: 0 0 20px 0;
      }
    }
  }

  .close {
    position: absolute;
    top: 10px;
    right: 10px;
    transition: transform .2s ease-in-out;

    &:hover {
      transform: scale(1.3);
    }
  }
}