@import '../../../assets/colors';

#infoView {
  display: flex;
  width: 100%;
  background-color: $bondiBlue;
  min-height: 45px;
  position: fixed;
  font-size: 19px;
  text-align: center;
  color: $white;
  transition: all 0.3s ease-in;
  z-index: 10;
  bottom: 0;
  justify-content: center;
  align-items: center;
  align-content: center;
  box-shadow: 0px 0px 9px -2px rgba($black, 0.75);

  > .message {
    height: 100%;
    flex: 1;
    text-align: center;
    font-size: 16px;
  }

  &.display-info {
    opacity: 1;
  }

  &.hide-info {
    transform: translateY(100%);
    opacity: 0;
    z-index: -1 !important;
    pointer-events: none;
  }

  .loading-background {
    animation: loading-animation 5s linear infinite;
  }

  .remove-info-box {
    height: 45px;
    width: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    &:hover {
      filter: saturate(50%);
    }

    .remove-info {
      font-size: 130%;
      color: white;
      opacity: 0.5;
      transform-origin: center;
      transition: all 0.1s ease-in;

      &:hover {
        transform: scale(1.2, 1.2);
        opacity: 1;
      }
    }
  }
}
