@mixin width800px {
  @media screen and (max-width: 800px) {
    @content
  }
}

@mixin width1180px {
  @media screen and (max-width: 1180px) {
    @content
  }
}

@mixin width($size) {
  @media screen and (max-width: $size) {
    @content
  }
}
@mixin phone {
  @media screen and (max-width: 768px) {
    @content
  }
}
@mixin tablette {
  @media screen and (max-width: 992px) {
    @content
  }
}
@mixin paysage-phone{
    @media screen and (max-width: 768px) and (orientation: landscape) {
@content
}
}