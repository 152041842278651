@import './assets/colors';

@import url('http://fonts.cdnfonts.com/css/helvetica-neue-9');

@font-face {
  font-family: 'BlogScriptW00-Regular';
  src: url('//db.onlinewebfonts.com/t/1412bea64d9ba801365a8a4b8c6ee782.eot');
  src: url('//db.onlinewebfonts.com/t/1412bea64d9ba801365a8a4b8c6ee782.eot?#iefix')
      format('embedded-opentype'),
    url('//db.onlinewebfonts.com/t/1412bea64d9ba801365a8a4b8c6ee782.woff2') format('woff2'),
    url('//db.onlinewebfonts.com/t/1412bea64d9ba801365a8a4b8c6ee782.woff') format('woff'),
    url('//db.onlinewebfonts.com/t/1412bea64d9ba801365a8a4b8c6ee782.ttf') format('truetype'),
    url('//db.onlinewebfonts.com/t/1412bea64d9ba801365a8a4b8c6ee782.svg#BlogScriptW00-Regular')
      format('svg');
}

* {
  margin: 0;
  padding: 0;
  font-family: 'Ubuntu', sans-serif;
  box-sizing: border-box;
  transition: all 0.5s;
}

*:not(input):not(textarea) {
  user-select: none;
}

body {
  // background: $bondiBlue;
  scroll-behavior: smooth;
  padding-bottom: 64px;
}

/* Animation */
.fade {
  animation-name: fade;
  animation-duration: 0.5s;
  @keyframes fade {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
}

.mini-icon-container {
  display: flex;
  flex-direction: column;

  img {
    object-fit: contain;
  }

  p {
    color: $saffron !important;
    font-size: 12px !important;
    text-transform: uppercase;
    text-align: center;
    font-family: 'BlogScriptW00-Regular', serif;
  }
}
