@import '../../../../assets/colors';
@import '../../../../assets/dimens';

#projects {
  @include phone{
    padding-top: 35px;
 
  }
  .arrow-back {
    display: none;
  }

  .background {
    width: 100%;
    position: absolute;
    top: 0;
    right: -1px;
    left: -1px;
    z-index: -1;
  }

  .navigation {
    display: flex;
    margin-bottom: 8px;
    background-color: $white;
    height: 64px;
    align-items: center;
    border-radius: 1px;
    box-shadow: 0 3px 4px 0 rgba(10, 15, 44, 0.2), 0 4px 16px 0 rgba(104, 129, 151, 0.15), 0 8px 26px 0 rgba(0, 0, 0, 0.15);

    @include phone {
      margin-bottom: 0;
    }

    .links {
      padding: 0 6px;

      p {
        color: $codGray;
        font-size: 24px;
        font-weight: bold;
        line-height: 32px;
        margin: 0;
      }
    }
  }

  >.container {
    display: flex;
    flex-direction: column;
    margin: 65px 56px 0 56px;

    @include phone {
      padding: 0 16px;
      margin: unset;
    }
    @include tablette {
      padding: 0 16px;
      margin: unset;
    }
  }

  .no-projects {
    text-align: center;
  }
}
