@import "../../../assets/colors.scss";
.confetti-wrapper{
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  // background-color: rgba(0, 0, 0, 0.5);
  transition: opacity 0.1s ease-in;
  pointer-events: none;
  z-index: 999;
  opacity: 0;
  &.show{
    opacity: 1 !important;
  }
  &.hide{
    opacity: 0 !important;
  }
}