@import "../../../../assets/colors";

.password-form {
  p {
    color: $codGray;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 22px;
  }

  .separator {
    width: 100%;
    border-bottom: 1px dashed $alto;
    margin-top: 24px;
    margin-bottom: 24px;
  }

  .wrapper {
    width: 100%;
    display: flex;
    flex-direction: row;
    margin-bottom: 24px;

    .icon-input {
      width: 50%;

      &:first-of-type {
        margin-right: 16px;
      }

      &:last-of-type {
        margin-left: 16px;
      }

      .input-wrapper {
        padding-left: 0;
        background: $athensGrayDark;
        border-bottom: 2px solid $athensGrayDark;

        input {
          height: 38px;
          font-size: 13px;
          line-height: 24px;
          color: $armadillo;
        }
      }
    }

    &.half {
      width: 50%;

      .icon-input {
        width: calc(100% - 16px);

        &:last-of-type {
          margin-left: 0;
        }
      }
    }
  }

  .action {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    .button {
      width: 170px;
    }
  }
}

@media screen and (max-width: 768px) {
  .password-form .wrapper {
    flex-direction: column;
    margin-bottom: 0;

    &.half {
      width: 100%;

      .icon-input {
        width: 100%;
      }
    }

    .icon-input {
      width: 100%;
      margin-bottom: 24px;

      &:first-of-type {
        margin-right: 0;
      }

      &:last-of-type {
        margin-left: 0;
      }
    }
  }
}