
@import '../../../../assets/colors';
.circular-progress{
  position: relative;
  height: 123px;
  width: 123px;
  // transform: rotate(90deg);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  &::before{
    content: '';
    position: absolute;
    height: 100px;
    width: 100px;
    background-color: $white;
    border-radius: 50%;
  }
  .value-container{
    position: absolute;
    font-size: 24px;
    color: $codGray50;
    font-size: 20px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 24px;
    transform:rotate(360deg)
  }
}