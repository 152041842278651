// (Colors are named with http://chir.ag/projects/name-that-color/#71B239)
$abbey: #535D5F;
$alto: #D1D1D1;
$armadillo: #433C35;
$athensGray: #F8F8F9;
$athensGrayDark: #EFF2F4;
$atlantis: #8CC73F;
$black: #000000;
$black20: rgba(0,0,0,0.2);
$bombay: #B1B2BA;
$bondiBlue: #00A0AF;
$cerulean: #0093E0;
$cerulean50: rgba(102, 174, 249, 0.5); //blue 50%
$ceruleanLight: #00A3DA;
$citron: #99C11B;
$codGray: #1E1E1E;
$codGray50:#262626;
$codGray10: #626366;
$codGray60: rgba(30, 30, 30, 0.7);
$doveGray: #696969;
$dustyGray: #979797;
$shadow:#E4E4E4;
$shadow2: #DFDFDF;
$flamePea: #D75B31;
$flamingo: #F16122;
$orange-200: #FCE3CB;
$geyser: #D9DFE5;
$hitGrey: #A2B4B8;
$iron: #cbd1d6;
$jungleGreen: #31B56F;
$jungleGreen2: #29828C;
$green-500:#00763D;
$green-100: #E9FFF4;
$lynch: #688197;
$mountainMeadow: #1DB46C;
$mineShaft: #221F1F;
$mystic: #E2E7EB;
$nevada: #6D6E71;
$pistachio: #91CF01;
$red: #E60000; //error
$rollingStone: #748285;
$saffron: #f2b843;
$scarlet: #EA1806;
$seashell: #F1F1F1;
$shark: #27282B;
$sorbus: #FD8204;
$stiletto: #962F34;
$stormDust: #6F6F6E;
$sun: #FDB614;
$treePoppy: #F7941E;
$wattle: #D0DF5D;
$wattle20: rgba(208, 223, 93, 0.2);
$white: #FFFFFF;
$white95: rgba(255, 255, 255, 0.95);
$white70: rgba(255, 255, 255, 0.7);
$white30: rgba(255, 255, 255, 0.3);
$wildSand: #F5F5F5;
$white100:#F3FEFF;
$gray-700: #707070;
