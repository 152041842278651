@import '../../../assets/colors';
@import '../../../assets/dimens';

#detailBudget {
  .background {
    width: 100%;
    position: absolute;
    top: 0;
    right: 0;
    z-index: -1;
  }

  .navigation {
    display: flex;
    padding-left: 62px;
    margin-bottom: 32px;
    background-color: $white;
    height: 64px;
    align-items: center;
    border-radius: 1px;
    box-shadow: 0 3px 4px 0 rgba(10, 15, 44, 0.2), 0 4px 16px 0 rgba(104, 129, 151, 0.15),
      0 8px 26px 0 rgba(0, 0, 0, 0.15);

    p {
      margin-left: 40px;
      color: $codGray;
      font-size: 16px;
      font-weight: bold;
      line-height: 32px;
    }

    i {
      font-size: 32px;
      color: $bondiBlue;
      cursor: pointer;
    }
  }

  .main {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    padding: 30px 50px;
    margin-top: 60px;
    margin-bottom: 48px;

    @include phone {
      padding: 5px 16px !important;
    }
    .entries {
      display: flex;
      flex-direction: column;
      background-color: $white;
      width: 85%;
      overflow: hidden;
      border-radius: 5px;
      box-shadow: 0 3px 4px 0 rgba(10, 15, 44, 0.2), 0 4px 16px 0 rgba(104, 129, 151, 0.15),
        0 8px 26px 0 rgba(0, 0, 0, 0.15);
      min-height: 316px;
      .listeItemSmall {
        display: none;
      }
      @include phone {
        min-height: initial;
      }

      .header,
      .headerMobile {
        display: flex;
        padding: 0 12px;

        > .col p {
          font-size: 16px;
          color: $rollingStone;
          @include phone {
            font-size: 11px;
          }
        }
      }

      .budget-item {
        display: flex;
        align-items: center;
        padding: 5px 12px;
        font-size: 15px;
        font-weight: 500;
        @include phone {
          font-size: 13px;
        }
        .amount p {
          color: $green-500;

          &.negative {
            color: $scarlet;
          }
        }

        &.selectable {
          &:hover {
            background-color: darken($athensGray, 10) !important;
            cursor: pointer;
          }
        }
      }

      .col {
        display: flex;
        margin: 12px 0 12px 0;
        overflow: hidden;

        > p {
          max-width: 190px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        &.date {
          display: flex;
          flex: 2;
        }

        &.object {
          display: flex;
          flex: 3;
        }

        &.type {
          display: flex;
          flex: 3;
        }

        &.account {
          display: flex;
          flex: 1.5;
        }

        &.execution {
          display: flex;
          flex: 2;
        }

        &.amount {
          display: flex;
          flex: 2;
        }

        &.delete {
          display: flex;
          flex: 0.5;
          padding-right: 8px;
        }
      }

      .list {
        display: flex;
        flex-direction: column;

        .budget-item:nth-child(2n + 1) {
          background-color: $athensGray;
        }
      }

      .footer {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        margin-top: 48px;
        padding: 22px;
        gap: 16px;
        box-shadow: 0 -8px 8px -6px rgba(0, 0, 0, 0.12), 0 -11px 8px -11px rgba(0, 0, 0, 0.24);
        @include phone {
          padding: 22px 5px;
          column-gap: 5px;
        }
        .button {
          // width: 200px;
          height: 40px;
        }
        .btn_outlined {
          border: solid 2px $green-500 !important;
          background: white;

          .with-icon {
            color: $green-500 !important;
            .material-icons {
              color: $gray-700 !important;
              opacity: 1 !important;
            }
          }
        }
      }

      .material-icons {
        border: none;
        outline: none;
        opacity: 1;
        &:hover {
          cursor: pointer;
          opacity: 1;
        }
      }
    }

    .summary {
      display: flex;
      flex-direction: column;
      background-color: $white;
      margin-left: 42px;
      overflow: hidden;
      padding: 15px;
      width: 15%;
      box-shadow: 0 3px 4px 0 rgba(10, 15, 44, 0.2), 0 4px 16px 0 rgba(104, 129, 151, 0.15),
        0 8px 26px 0 rgba(0, 0, 0, 0.15);
      padding: 13px 25px;
      border-radius: 5px;

      .item {
        //border-bottom: 1px solid #d1d1d1;
        padding: 5px 0;
        font-family: 'Helvetica Neue', sans-serif !important;

        &.last {
          border-style: none;
        }
      }

      p {
        font-size: 22px;
        font-weight: bold;
        color: $green-500;
        text-align: left;
        &.negative {
          color: $scarlet;
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    .main {
      flex-direction: column-reverse;
      height: 100%;
      padding: 0 !important;
      margin-top: 43px;
      padding-left: 20px !important;
      padding-right: 20px !important;

      .entries,
      .summary {
        width: 100%;
      }
      .entries {
        margin-bottom: 20px;
        .listeItemLarge,
        .header {
          display: none !important;
        }
        .listeItemSmall {
          display: block;
          .col {
            margin: 0 !important;
          }
          .bugederListItem {
            padding: 10px;
            .descriptionArea {
              padding-left: 13px !important;
              padding-right: 13px !important;
              padding-top: 15px;
              display: block;
              position: relative;
              transition: all 0.5s;
              height: auto;
              .descriptionAreaItem {
                margin-bottom: 16px;
                position: relative;
                .descriptionTitle {
                  font-size: 11px;
                  color: $gray-700;
                }
                .descriptionData {
                  font-size: 12px;
                  font-weight: 400;
                }
              }
              &.show {
                height: auto;
                display: block;
              }
              &.hide {
                height: 0px;
                display: none;
              }
            }
            .itemAction {
              padding-top: 20px;
              display: flex;
              padding-left: 13px !important;
              padding-right: 13px !important;
              justify-content: space-between;
              p {
                font-size: 12px;
                color: $green-500;
                font-weight: 500;
                cursor: pointer;
              }
              .col {
                display: flex;
                justify-content: flex-end;
                i {
                  color: $gray-700 !important;
                }
              }
            }
          }
        }
      }
      .summary {
        flex-direction: row;
        margin: 0;
        margin-bottom: 32px;
        .item {
          flex: 1;
          border-bottom: 1px solid transparent;

          &.header {
            flex: 4;
          }

          p {
            font-family: sans-serif !important;
            text-align: left;
            white-space: nowrap;
          }
        }
      }

      .footer {
        padding-left: 20px !important;
        padding-right: 20px !important;
        display: flex;
        flex-direction: column !important;
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        background: $white;
      }
    }
  }
}
