@import "../../../assets/colors";

.checkbox {
  display: block;
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;

    &:checked ~ .checkmark {
      border: 2px solid green;
      background-color: green;
    }

    &:checked ~ .checkmark:after {
      display: block;
    }
  }

  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    border: 2px solid #808285;
    border-radius: 5px;
    background-color: white;
    transition: all 0.2s ease-in;

    &:after {
      content: "";
      position: absolute;
      display: none;

      left: 7px;
      top: 2px;
      width: 5px;
      height: 10px;
      border: solid white;
      border-width: 0 3px 3px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }

    &.disabled {
      cursor: not-allowed;
      opacity: 0.25;
    }
  }

  .label {
    margin-left: 40px;
  }
}
