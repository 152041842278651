@import '../../../../assets/colors';
@import '../../../../assets/dimens.scss';
#detailSuccess {
  .background {
    width: 100%;
    position: absolute;
    top: 0;
    z-index: -1;
  }

  > .container {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    background-color: $white;
    margin: 70px 56px;
    padding-top: 28px;
    border-radius: 5px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.12), 0 4px 8px 2px rgba(0, 0, 0, 0.24);
    position: relative;
    padding: 32px;
    box-sizing: border-box;
    .row {
      /*       display: flex;
      justify-content: space-between;
      align-items: center; */

      .added_success {
        color: $green-500;
        font-size: 14px;
        font-weight: bold;
        line-height: 18px;
        padding-top: 16px;
      }
      .left {
        width: 50%;
        @include phone {
          width: 100%;
        }
        h1 {
          color: $codGray50;
          font-size: 20px;
          font-weight: bold;
          margin-bottom: 10px;
        }

        h2 {
          color: $armadillo;
          font-size: 14px;
          font-weight: bold;
          line-height: 16px;
          padding-bottom: 16px;
        }
        .purcent {
          color: $codGray50;
          font-family: Ubuntu;
          font-size: 14px;
          font-weight: bold;
          letter-spacing: 0;
          line-height: 18px;
          margin: 5px 0;
        }
        .progress {
          display: flex;
          align-items: center;
          width: 100%;
          .bar {
            flex: 1;
            border-radius: 7px;
            height: 10px;
            width: 100%;
            background-color: $shadow2;
            .inner {
              background-color: $green-500;
              border-radius: 7px;
              height: 100%;
            }
          }
        }
      }
    }
    .successes {
      h2,
      h3 {
        color: $codGray50;
        font-family: Ubuntu;
        font-size: 15px;
        font-weight: bold;
        letter-spacing: 0;
      }
      h2 {
        margin: 32px 0;
      }
      .unacheved_success {
        .terminated_success {
          color: $green-500;
          font-size: 14px;
          font-weight: bold;
          line-height: 18px;
        }
        .unacheved_container {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 20px;
          flex-wrap: wrap;
        }
      }
      .acheved_success {
        position: relative;
        .acheved_container {
          display: flex;
          align-items: center;
          gap: 20px;
          flex-wrap: wrap;

          .page-count {
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            width: 100%;
            height: 100px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-weight: 500;
            font-size: 15px;
            line-height: 16px;
          }

          ul {
            display: flex;
            list-style: none;
            justify-content: space-between;

            li {
              display: none !important;

              &.previous,
              &.next {
                display: flex !important;
                position: absolute;
                cursor: pointer;
                z-index: 1;
                .page-swip {
                  display: flex;
                  align-items: center;
                  color: $codGray50;
                  font-family: Ubuntu;
                  font-size: 15px;
                  font-weight: 500;
                  letter-spacing: 0;
                  line-height: 16px;
                  text-align: right;
                }
              }

              &.previous {
                left: 0 !important;
                bottom: 43px !important;
              }

              &.next {
                right: 0 !important;
                bottom: 43px !important;
              }
            }
          }
        }
        .acheved_success_footer {
          width: 100%;
          margin-top: 43px;
          height: 45px;
        }
      }
    }
    .footer {
      z-index: 2;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      height: 86px;
      margin-top: 34px;
      border-radius: 5px;
      box-shadow: 0 -8px 8px -6px rgba(0, 0, 0, 0.12), 0 -11px 8px -11px rgba(0, 0, 0, 0.24);
      position: fixed;
      left: 0;
      bottom: 0;
      right: 0;
      background-color: $white;
      .action-button {
        height: 40px;
        margin-right: 60px;
      }
    }

    .no-success {
      text-align: center;
      margin: 22px 0 8px 8px;
    }
  }

  @media screen and (max-width: 768px) {
    .unacheved_container {
      justify-content:  center;
    }
    .navigation {
      padding-left: 32px;
    }

    > .container {
      margin: 30px 0;
      margin-top: 10px;
      padding: 10px;
      .left{
        padding: 10px;
      }
      .unacheved_success h2{
        padding: 10px;
        margin: 0;
      }
      .header-web {
        visibility: hidden;
        height: 0;
        width: 0;
        margin: 0;
      }

      .footer {
        justify-content: center;
        margin: 0;
        // box-shadow: 0 0 0 0;
        .button{
          margin: 0 !important;
        }
      }
    }
  }
  @media screen and (max-width: 468px) {
    .unacheved_container {
      // flex-direction: column !important;
      justify-content:  center;
      gap: 0 !important;
      padding: 0;
    }
  }
}
