@import '../../../../../assets/colors.scss';
.addsuccess-item {
  * {
    box-sizing: border-box
  }
  &.bordered{
    border: 2px solid $green-500;
  }
  height: 230px;
  width: 150px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 5px;
  cursor: pointer;
  background-color: $white;
  .header-content {
    height: 50%;
    width: 100% !important;
    background-color: transparent;
    border-radius: 5px;
    position: relative ;
    img {
      width: 100% !important;
      height: 100% !important;
      object-fit: contain;
      border-radius: 5px;
    }
    i{
      position: absolute !important;
      right: -117px !important;
      top: 0 !important;
     color: $green-500;
      &.fill-1 {
        height: 12px;
        width: 12px;
        color: $green-500;
      }
    }
  }

  .success-detail {
    height: 55%;
    width: 100%;
    border-radius: 5px;
    background-color: $white;
    box-shadow: 0 3px 4px 0 rgba(10, 15, 44, 0.2), 0 4px 16px 0 rgba(104, 129, 151, 0.15), 0 8px 26px 0 rgba(0, 0, 0, 0.15);
    position: absolute;
    left: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-size-adjust: auto;
    -webkit-text-size-adjust: auto;

    h4 {
      color: $codGray50;
      font-family: Ubuntu;
      font-size: 12px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 16px;
      text-align: center;
      padding: 5px 5px 0 5px;
    }

    .actions {
      display: flex;
      justify-content: space-between;
      flex-wrap: nowrap;
      padding: 8px;
      position: relative;

      i {
        height: 25px;
        width: 15px;
        margin-right: 10px;
        color: $nevada;
      }

      .btn {
        i {
          color: $green-500;

          &.fill-1 {
            height: 12px;
            width: 12px;
            color: $green-500;
          }
        }
      }

    }
  }
}