@import '../../../../../assets/colors';
@import '../../../../../assets/dimens';

.icon-picker {
  // width: 580px;
  position: fixed;
  background-color: $white;
  top: 50%;
  left: 5%;
  right: 5%;
  box-shadow: 0 3px 4px 0 rgba(10, 15, 44, 0.2), 0 4px 16px 0 rgba(104, 129, 151, 0.15),
    0 8px 26px 0 rgba(0, 0, 0, 0.15);
  flex-direction: column;
  padding: 12px;
  cursor: default;
  border-radius: 5px;
  transform: translateY(-50%);
  z-index: 50;
  @include phone {
    transform: translateY(-14%);
  }
  .title {
    font-size: 14px;
    color: $doveGray;
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
  }

  .icon-list {
    display: flex;
    list-style: none;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;

    .icon {
      margin: 6px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 80px;
      height: 80px;
      cursor: pointer;
      padding: 16px;
      border-radius: 3px;
      border: 1px solid rgba($black, 0.08);

      @keyframes icon {
        0% {
          transform: skewX(0deg);
        }
        10% {
          transform: skewX(-10deg);
        }
        20% {
          transform: skewX(10deg);
        }
        25% {
          transform: skewX(-8deg);
        }
        30% {
          transform: skewX(8deg);
        }
        33% {
          transform: skewX(-4deg);
        }
        36% {
          transform: skewX(4deg);
        }
        40% {
          transform: skewX(0deg);
        }
      }

      i,
      .social-icon {
        font-size: 32px;
        margin-bottom: 5px;
        color: $bondiBlue;

        &:hover {
          color: $bondiBlue;
          animation-name: icon;
          animation-duration: 1s;
        }
      }

      img {
        width: 36px !important;
      }

      p {
        font-size: 10px;
        margin-top: 3px;
        white-space: nowrap;
      }
    }
  }
}
