@import '../../../../assets/colors';
@import '../../../../assets/dimens';

.link-item {
  background-color: $wildSand;
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  padding: 8px;
  animation-name: hauteur;
  animation-duration: 1s;

  @keyframes hauteur {
    from { height: 0px;}
    to { height: 12px; }
  }

  @include width(800px) {
    flex-direction: column;
  }

  >.icon-label {
    display: flex;

    i {
      color: $bondiBlue;

      &.not-selected {
        position: relative;
        left: 20px;
      }
    }

    @include width(800px) {
      width: 100%;
    }

    .drop-down:not(.icon-picker) {
      width: 96px;
      min-width: 96px;
      height: 46px;
      border: 1px solid $geyser;
      margin-right: 8px;
      background-color: $white;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-around;
      color: $lynch;
      position: relative;

      >i {
        font-size: 20px;
      }

      >.tuzzit {
        width: 12px;
      }

      &.has-errors {
        border: 1px solid $red;
      }

      &.is-editing {
        cursor: pointer;
      }
    }

    .label {
      width: 100%;
      border: 1px solid $geyser;
      display: flex;
      align-items: center;
      padding: 0 16px;
      font-size: 13px;
      background-color: $white;
      height: 46px;
      outline: none;
      margin-right: 8px;

      &::placeholder {
        color: rgba($black, 0.3);
      }
      
      &.has-errors {
        border: 1px solid $red;
      }

      @include width(800px) {
        flex: 1;
        margin-right: initial;
        margin-bottom: 8px;
      }
    }
  }

  .url {
    flex: 1;
    border: 1px solid $geyser;
    display: flex;
    align-items: center;
    padding: 0 16px;
    font-size: 13px;
    background-color: $white;
    height: 46px;
    outline: none;
    margin-right: 8px;

    &::placeholder {
      color: rgba($black, 0.3);
    }
    
    &.has-errors {
      border: 1px solid $red;
    }

    @include width(800px) {
      width: 100%;
      margin-right: initial;
      flex: initial;
      margin-bottom: 8px;
    }
  }

  >.button-trash {
    display: flex;
    justify-content: center;
    align-items: center;

    @include width(800px) {
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;
    }

    .button {
      height: 40px;
      width: 130px;
      margin: 0 20px 0 8px;

      @include width(800px) {
        margin-left: initial;
      }

      .material-icons {
        font-size: 20px;
      }
    }

    >.material-icons {
      color: $lynch;

      &.create {
        color: $lynch;

        &:hover {
          color: $geyser;
          cursor: initial;
        }
      }

      &:hover {
        cursor: pointer;
        color: $red;
      }
    }
  }
}
