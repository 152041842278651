@import '../../../assets/colors.scss';
.calendar {
  width: 100%;
  height: 100%;
  .month {
      width: 100%;
      padding: 16px 17px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      text-align: center;
      i{
        font-size: 20px;
        color: $rollingStone;
        cursor: pointer;
      }
      h1{
        color: #262626;
        font-family: Ubuntu;
        font-size: 24px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 32px;
      }
    }
  .weekdays {
    width: 100%;
    padding: 16px 0;
    display: flex;
    align-items: center;
    div {
        letter-spacing: 0.1rem;
        // width: calc(100% / 7);
        width: calc(44.2rem / 7);
        display: flex;
        justify-content: center;
        align-items: center;
        color: $jungleGreen2;
        font-family: Ubuntu;
        font-size: 24px;
        font-weight: bold;
        line-height: 32px;
      }
  }
  .days {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    padding: 0.2rem;
    div {
        font-size: 1.4rem;
        margin: 0.3rem;
        width: calc(40.2rem / 7);
        height: 5rem;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: background-color 0.2s;
        &:hover{
          &:not(.today) {
              background-color: #262626;
              border: 0.2rem solid #777;
              cursor: pointer;
            }
        }
      }
    .today {
      background-color:$jungleGreen2 ;
      color: $white;
    }
  }
    .prev-date,
    .next-date {
      opacity: 0.5;
    }
  
   
}