@import '../../../assets/colors';
@import '../../../assets/dimens';

#projectData {
  .background {
    width: 100%;
    position: absolute;
    top: 0;
    right: -1%;
    left: -1%;
    z-index: -1;
  }

  .data-form {
    margin: 70px 55px;
    background: $white;

    ul.classes {
      width: 100%;

      li {
        width: 100%;
        display: flex;
        flex-direction: column;
        padding: 24px 24px 0 24px;
        @include phone{
            padding: 24px 10px 0 10px;
        }
        > .label {
          margin-bottom: 12px;
          color: $codGray;
          font-family: 'Ubuntu', sans-serif;
          font-size: 18px;
          font-weight: bold;
          letter-spacing: 0;
          line-height: 24px;
        }

        > .wrapper {
          display: flex;
          flex-direction: row;
          padding: 8px 12px;
          background: $wildSand;
          margin-top: 4px;
          margin-bottom: 4px;

          .label {
            width: 140px;
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-left: 16px;
            color: $lynch;
            font-family: 'Roboto', sans-serif;
            font-size: 13px;
            letter-spacing: 0;
            line-height: 24px;
          }

          .drop-down {
            width: 80%;

            .label {
              display: none;
            }

            .selector-container {
              border: 1px solid $geyser;

              .box {
                background-color: $white;
              }
            }
          }

          .composition {
            width: 400px;
            display: flex;
            flex-direction: row;

            .input {
              width: 180px;
            }

            &:first-of-type {
              margin-right: 40px;
            }
          }
        }
      }
    }

    ul.times {
      width: calc(100% - 2 * 24px);
      margin: 24px;

      li {
        width: 100%;
        display: flex;
        flex-direction: column;
        margin: 8px 0;

        > .wrapper {
          display: flex;
          flex-direction: row;
          align-items: center;
          padding: 12px;
          background: $wildSand;

          .container {
            display: flex;
            flex-direction: row;
            align-items: center;

            .index {
              min-width: 40px;
              width: 40px;
              height: 40px;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              margin-left: 12px;
              margin-right: 12px;
              border-radius: 50%;
              background: $green-500;
              color: $white;
            }

            .drop-down {
              max-width: calc(100% - 40px - 12px * 4);
              flex: 1;
              margin-left: 12px;
              margin-right: 12px;

              .label {
                display: none;
              }

              .selector-container {
                border: 1px solid $geyser;

                .box {
                  background-color: $white;
                }
              }
            }

            .label-wrapper {
              min-width: 150px;
              flex: 0;
              display: flex;
              flex-direction: column;
              margin-left: 80px;
              margin-right: 12px;

              .volume {
                color: $codGray;
                font-family: 'Ubuntu', sans-serif;
                font-size: 18px;
                letter-spacing: 0;
                line-height: 20px;
                text-align: right;
              }

              .annual {
                color: $codGray;
                font-family: 'Ubuntu', sans-serif;
                font-weight: bold;
                font-size: 18px;
                letter-spacing: 0;
                line-height: 20px;
                text-align: right;
                text-transform: uppercase;
              }
            }

            .input {
              width: 100px;
            }

            &.first {
              flex: 1;
              max-width: calc(100% - 280px);
            }

            &.second {
              min-width: 280px;
            }
          }
        }
      }
    }

    .delete {
      align-self: flex-end;
      margin-top: 8px;
      padding: 8px 10px;
    }

    .separator {
      height: 1px;
      margin-top: 8px;
      background: $alto;
    }

    > .label {
      color: $codGray;
      font-family: 'Ubuntu', sans-serif;
      font-size: 18px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 24px;
      margin: 24px 12px 0 24px;
    }

    .summary {
      display: flex;
      flex-direction: row;
      padding: 24px;

      .button {
        padding: 8px 10px;
      }

      .wrapper {
        flex: 1;
        display: flex;
        flex-direction: row;
        align-items: center;

        .label {
          flex: 1;
          margin-right: 12px;
          color: $armadillo;
          font-family: 'Ubuntu', sans-serif;
          font-size: 18px;
          font-weight: bold;
          letter-spacing: 0;
          line-height: 24px;
          text-align: right;
        }

        .value {
          color: $atlantis;
          font-family: 'Ubuntu', sans-serif;
          font-size: 24px;
          font-weight: bold;
          letter-spacing: 0;
          line-height: 32px;
        }
      }
    }

    .save {
      display: flex;
      flex-direction: row-reverse;
      margin-top: 24px;
      padding: 24px;
      box-shadow: 0 -8px 8px -6px rgba(0, 0, 0, 0.12), 0 -11px 8px -11px rgba(0, 0, 0, 0.24);
      .button {
        width: 25%;
        padding: 12px 10px 12px 10px;
        @include phone{
          width: 100%;
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  #projectData {
    .data-form {
      ul.classes li > .wrapper.wrapper-composition .composition .label {
        width: auto;
        flex: 1;
      }
      ul.times li > .wrapper .container .label-wrapper {
        margin-left: 0;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  #projectData {
    #screenHeader .links p:first-child {
      display: flex;
    }
  }
}

@media screen and (max-width: 768px) {
  #projectData {

    .data-form {
      margin: 12px 8px;

      ul.classes li > .wrapper {
        flex-direction: column;

        .label {
          width: 100%;
          margin-left: 0;
        }

        .drop-down {
          width: 100%;
        }

        &.wrapper-composition {
          flex-direction: row;

          .composition {
            width: calc(50% - 12px);
            flex-direction: column;

            .label, .input {
              width: 100%;
            }

            &:first-of-type {
              margin-right: 12px;
            }

            &:last-of-type {
              margin-left: 12px;
            }
          }
        }
      }

      ul.times li > .wrapper {
        flex-direction: column;

        .container {

          .drop-down {
            max-width: calc(100% - 40px - 12px * 3);
            margin-right: 0;
          }

          .label-wrapper {
            flex: 1;
          }

          &.first {
            max-width: 100%;
            width: 100%;
          }

          &.second {
            max-width: 100%;
            width: 100%;
            margin-top: 12px;
          }
        }
      }

      .summary {
        flex-direction: column;

        .wrapper {
          margin-top: 24px;

          .label {
            text-align: left;
          }
        }
      }
    }
  }
}

//@media screen and (max-width: 800px) {
//  #projectData {
//    #screenHeader {
//      padding: 0 24px;
//
//      .links {
//        display: flex;
//        flex-direction: column;
//        flex: 1;
//        padding: 0 12px;
//        font-size: 14px;
//
//        p {
//          margin: 3px 0;
//        }
//
//        p:first-child {
//          font-weight: bold;
//        }
//      }
//    }
//  }
//}
