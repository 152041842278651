@import "../../../assets/colors";

.notifications {
  width: 400px;
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: $wildSand;
  box-shadow: 0 3px 4px 0 rgba(10, 15, 44, 0.2), 0 4px 16px 0 rgba(104, 129, 151, 0.15), 0 8px 26px 0 rgba(0, 0, 0, 0.15);
  transform: translateX(100%);
  transition: transform cubic-bezier(0.075, 0.82, 0.165, 1) 0.3s;
  z-index: 100;

  &.displayed {
    transform: translateX(0);
  }

  > .header {
    display: flex;
    flex-direction: row;
    padding: 24px 24px 24px 32px;
    color: $bondiBlue;
    font-size: 34px;
    letter-spacing: 0;
    line-height: 32px;
    background: $white;

    p {
      flex: 1;
    }

    i {
      font-size: 20px;
      color: $rollingStone;
      cursor: pointer;
    }
  }

  .container {
    overflow: scroll;
    .notif {
      padding: 32px 40px 20px 32px;

      > .header {
        display: flex;
        flex-direction: row;
        margin-bottom: 12px;
        align-items: flex-end;

        > p {
          flex: 1;
          color: #000000;
          font-family: Ubuntu;
          font-size: 20px;
          font-weight: 500;
          letter-spacing: 0;
          line-height: 32.28px;
        }

        .date {
          min-width: 120px;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-end;

          p {
            color: $doveGray;
            font-size: 12px;
            letter-spacing: 0;
            line-height: 12px;
            text-align: right;
          }

          .badge {
            width: 8px;
            height: 8px;
            margin-left: 16px;
            border-radius: 50%;
            background: $hitGrey;

            &.new {
              background: $flamingo;
            }
          }
        }
      }

      .content {
        > p {
          color: $black;
          font-size: 13px;
          letter-spacing: 0;
          line-height: 20px;
          white-space: pre-wrap;
        }

        .links {
          display: flex;
          flex-direction: row;
          margin-top: 16px;

          .button {
            padding: 12px 20px;
            flex-direction: row-reverse;
            border: 1px solid $alto;
            background: $white;

            span {
              color: $cerulean;
              font-size: 12px;
              line-height: 16px;
            }

            i {
              color: $flamingo;
              margin-right: 8px;
              font-size: 16px;
            }

            &:hover {
              background: lighten($alto, 10);
            }

            &:first-of-type {
              margin-right: 8px;
            }

            &:last-of-type {
              margin-left: 8px;
            }
          }
        }
      }
    }

    .separator {
      height: 1px;
      margin: 0 40px 0 32px;
      background: rgba($black, 0.2);
    }
    .message {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
      p{
        color: $codGray60;
        font-style: italic;
        text-align: center;
      }
    }
  }
}

@media screen and (max-width: 780px) {
  .notifications {
    width: 100%;

    .container {
      .notif {
        > .header {
          flex-direction: column;

          > p {
            font-size: 18px;
            line-height: 24px;
          }

          .date {
            margin-top: 16px;
          }
        }

        .content .links {
          flex-direction: column;
          align-items: center;

          .button {
            &:first-of-type {
              margin-right: 0;
              margin-bottom: 8px;
            }

            &:last-of-type {
              margin-left: 0;
            }
          }
        }
      }
    }
  }
}
