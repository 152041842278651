@import '../../../assets/colors';
@import '../../../assets/dimens.scss';
#detail-succes {
  .background {
    width: 100%;
    position: absolute;
    top: 0;
    z-index: -1;
  }
  > .container {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    margin: 70px 56px;
    padding-top: 28px;
    border-radius: 5px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.12), 0 4px 8px 2px rgba(0, 0, 0, 0.24);
    position: relative;
    padding: 32px;
    box-sizing: border-box;
    &.white {
      background-color: $white;
    }
    &.success {
      background-color: $green-100;
    }
    h1,
    h2,
    h3 {
      color: $codGray50;
      font-size: 20px;
      font-weight: bold;
      line-height: 24px;
      padding: 16px 0;
    }
    .success-message {
      p {
        color: $green-500;
        font-family: Ubuntu;
        font-size: 16px;
        font-weight: bold;
        line-height: 22px;
      }
    }
    .warning-message {
      width: 65%;
      display: flex;
      align-items: center;
      column-gap: 8px;
      padding: 13px 14px 13px 8px;
      border-radius: 5px;
      background-color: $orange-200;
      .icon {
        svg {
          fill: $codGray50;
          size: 50px;
        }
      }
      .message {
        color: $codGray50;
        font-family: Ubuntu;
        font-size: 13px;
        font-weight: 500;
        line-height: 18px;
      }
      @include phone {
        width: 100%;
      }
    }
    .conseil {
      color: $armadillo;
      font-family: Ubuntu;
      font-size: 14px;
      font-weight: 500;
      line-height: 16px;
      padding: 8px 0;
      text-align: left;
    }
    .videos {
      .video-container {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 20px;
        width: 100%;
        @include phone {
          flex-direction: column !important;
          gap: 15px !important;
        }
      }
    }
    .documents {
      .document-container {
        display: flex;
        flex-direction: column;
        row-gap: 20px;
      }
    }
    .footer {
      z-index: 2;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      height: 86px;
      margin-top: 34px;
      border-radius: 5px;
      box-shadow: 0 -8px 8px -6px rgba(0, 0, 0, 0.12), 0 -11px 8px -11px rgba(0, 0, 0, 0.24);
      position: fixed;
      left: 0;
      bottom: 0;
      right: 0;
      background-color: $white;

      .action-button {
        height: 40px;
        margin-right: 60px;
        @include phone {
          height: unset !important;
          margin-right: unset !important;
        }
      }
      .outlinedBtn {
        color: $green-500;
        background: white;
        border: 2px solid $green-500;
        padding: 10px 25px;
        border-radius: 100px;
        font-weight: 700;

      }
    }

    .no-success {
      text-align: center;
      margin: 22px 0 8px 8px;
    }
  }
  // Video card styles
  .video-card {
    * {
      box-sizing: border-box;
    }
    &.checked {
      &::after {
        content: url('../../../assets/images/check_succes.svg');
        position: absolute;
        top: -13px;
        right: -11px;
      }
    }
    height: 280px;
    width: 23%;
    position: relative;
    border-radius: 5px;
    @include phone {
      width: 100%;
    }
    .card-header {
      width: 100%;
      height: 82%;
      position: relative;
      background: $bondiBlue;
      border-radius: 5px 5px 0 0;
      @include phone {
        width: 100%;
      }
      .video-item {
        width: 100%;
        height: 100%;
        padding: 0;
        margin: 0;
        border: 0;
        border-radius: 5px 5px 0 0;
      }
      .play-button {
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 1;
        background: transparent;
        img {
          object-fit: cover;
          cursor: pointer;
        }
      }
    }

    .card-footer {
      width: 100%;
      border-radius: 5px;
      background-color: $white;
      box-shadow: 0 2px 4px 0 rgba(209, 209, 209, 0.5);
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      margin: 0 !important;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 18px 16px;
      .title {
        color: $codGray50;
        font-family: Ubuntu;
        font-size: 13px;
        font-weight: bold;
        padding: 8xp;
        margin-right: 10px;
      }
      img {
        cursor: pointer;
      }
    }
  }
  //File Item style
  .file-item {
    padding: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 5px;
    background-color: $white;
    box-shadow: 0 2px 4px 0 rgba(209, 209, 209, 0.5);
    width: 50%;
    @include phone {
      width: 100%;
    }
    .title {
      p {
        text-align: left;
        &:nth-child(1) {
          color: $black;
          font-family: Ubuntu;
          font-size: 14px;
          font-weight: bold;
          letter-spacing: 0;
          line-height: 16px;
        }
        &:nth-child(2) {
          color: #535d5f;
          font-family: Ubuntu;
          font-size: 12px;
          font-weight: 500;
          letter-spacing: 0;
          line-height: 13px;
          text-transform: uppercase;
        }
      }
    }
    .download_file {
      text-decoration: none;
    }
  }

  @media screen and (max-width: 768px) {
    .navigation {
      padding-left: 32px;
    }

    > .container {
      margin: 30px 16px;
      padding: 16px;

      .header-web {
        visibility: hidden;
        height: 0;
        width: 0;
        margin: 0;
      }

      p {
        text-align: center;
      }

      .footer {
        justify-content: center;
        margin: 0;
        box-shadow: 0 0 0 0;
        flex-direction: column;
        padding: 10px;
        
        .action-button {
          margin-bottom: 10px;
          width: 100%;
        }
      }
    }
  }
}
