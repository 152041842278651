 @import '../../../../assets/colors.scss';
 @import '../../../../assets/dimens.scss';

 .validate-success{
   display: flex;
   justify-content: center;
   align-items: flex-start;
   padding-top: 20px;
   position: fixed;
   top: 0;
   right: 0;
   left: 0;
   bottom: 0;
   opacity: 0;
   background-color: $codGray60;
   transition: z-index, 
   opacity 0.1s ease-in;
   box-sizing: border-box;
  &.shown {
      z-index: 100;
      opacity: 1;
    }
  
    &.hidden {
      display: none;
      transition: none;
      z-index: -1;
    }
   .popups-calendar {
     width: 620px !important;
     display: flex;
     flex-direction: column;
     padding: 16px;
     background-color: $white;
     border-radius: 5px;
     box-shadow: 0 3px 4px 0 rgba(10, 15, 44, 0.2), 0 4px 16px 0 rgba(104, 129, 151, 0.15), 0 8px 26px 0 rgba(0, 0, 0, 0.15);
     @include phone{
      width: 100%;
     }
     .close {
       width: 44px;
       height: 44px;
       align-self: flex-end;
       padding: 12px;
       font-size: 20px;
       font-weight: bold;
       color: $stormDust;
       cursor: pointer;
     }

     //Header
     .header {
       display: flex;
       justify-content: space-between;
       align-items: center;
       padding-left: 80px;
         @include phone{
       padding-left: 16px;
     }
        i {
            font-size: 18px;
          }
       .header-title {
         color: $codGray50;
         font-size: 20px;
         font-weight: 400;
         line-height: 24px;
       }

      
     }

     //Content
     .calendar-content {
       width: 100%;
       padding: 16px 66px 30px 65px;
       @include phone{
        padding: unset;

       }
       .description{
        margin-bottom: 20px;
        p{
          color: $shark;
          font-family: Ubuntu;
          font-size: 14px;
          line-height: 20px;
          padding-left: 16px;
        }
       }
       .calendar{
        .DayPicker-Caption {
            div{
              margin-bottom: 0.5em;
              padding: 0 0.5em;
              text-align: center;
              color: $codGray50;
              font-family: Ubuntu;
              font-size: 22px;
              font-weight: bold;
              line-height: 32px;
            }
          }
          .DayPicker-Weekday{
            abbr{
              color: $jungleGreen2;
              font-family: Ubuntu;
              font-size: 24px;
              font-weight: bold;
              letter-spacing: 0;
              line-height: 32px;
            }
          }
        .DayPicker-Month{
          width: 100%;
        }
        .DayPicker-NavButton {
          right: unset !important;
          left: unset !important;
          position: absolute;
          display: inline-block;
          margin-top: 2px;
          width: 1.25em;
          height: 1.25em;
          background-position: center;
          background-size: 50%;
          background-repeat: no-repeat;
          color: $rollingStone;
          cursor: pointer;
          &.DayPicker-NavButton--prev {
            margin-right: unset;
              margin:0;
              left: 17px !important;
              top: 10px;
              background-image: url("../../../../assets/images/angle-left-solid.svg");
            }
            &.DayPicker-NavButton--next {
              margin-right: unset;
              margin: 0;
              right: 17px !important;
              top:10px;
              background-image: url("../../../../assets/images/angle-right-solid.svg");
            }
        }

       }
     }

   }




 
 }