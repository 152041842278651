@import '../../../assets/colors.scss';
@import "../../../assets/dimens.scss";
#detail_video{
  .background{
    width: 100%;
    position: absolute;
    top: 0;
    z-index: -1;
  }
 >.player-wrapper{
    display: flex;
    flex-direction: column;
   height: 95vh;  
    padding:105px 16px ;
    box-sizing: border-box;
    background-color:rgba(38, 38, 38);
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    .player{
      position: relative;
      overflow: hidden;
     width: 100%;
      height: 100%;
    }
    &.desktop{
      @include phone{
        display: none;
      }
    }
 }
 .player-wrapper-mobile{
  display: none;
  @include phone{
    padding:25px 0 ;
    display: flex;
    flex-direction: column;
    .k-player{
       .header{
      height: 320px;
      width: 100%;
    }
    .footer{
      padding: 16px 16px 0 16px;
      font-family: 'Helvetica Neue';
      font-size: 20px;
      font-weight: bold;
      line-height: 24px;
    }
    }
   .others,.documents{
    padding: 16px;
    h2{
      font-family: 'Ubuntu';
      font-size: 16px;
      font-weight: bold;
      line-height: 18px;
      padding: 16px 0;
    }
    .viewerListe{
      display: flex;
      justify-content: space-between;
      gap: 20px;
      flex-wrap: wrap;
       // Video card styles
      .video-card{
      
        *{
          box-sizing: border-box;
        }
            &.checked {    
              &::after {
                content: url("../../../assets/images/check_succes.svg") ;
                position: absolute;
                top: -13px;
                right: -11px;
              }
            }
        height: 200px;
        width: 47%;
        position: relative;
        border-radius: 5px; 
        .card-header{
          width: 100%;
          height: 80%;
          position: relative;
          background: $bondiBlue;
          border-radius: 5px 5px 0 0;
          iframe{
            border-radius: 5px;
          }
          .video-item {
              width: 100%;
              height: 100%;
              padding: 0;
              margin: 0;
              border: 0;
              border-radius: 5px 5px 0 0;
            }
            .play-button{
              position: absolute;
              left: 0;
              top: 0;
              bottom: 0;
              right: 0;
              display: flex;
              justify-content: center;
              align-items: center;
              z-index: 1;
              background: transparent;
              img{
                object-fit: cover;
                cursor: pointer;
              }
              
            }
            
        }
        
        .card-footer{
          width: 100%;
          border-radius: 5px;
          background-color: $white;
          box-shadow: 0 2px 4px 0 rgba(209, 209, 209, 0.5);
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          margin: 0 !important;
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 18px 16px;
          .title{
            color: $codGray50;
            font-family: Ubuntu;
            font-size: 13px;
            font-weight: bold;
            padding: 8xp;
            margin-right: 10px;
          }
          img{
            cursor: pointer;
          }
        }

      }
 
    }
     @include paysage-phone{
    display: none;
  }
   }
   .documents{
   

 .document-container{
 
   display: flex;
    flex-direction: column;
    gap: 20px;
     .file-item{
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 5px;
    background-color: $white;
    box-shadow: 0 2px 4px 0 rgba(209, 209, 209, 0.5);
    width: 100%;
    padding: 10px;
    .title{
      p{
        text-align: left;
        &:nth-child(1){
          color: $black;
          font-family: Ubuntu;
          font-size: 14px;
          font-weight: bold;
          letter-spacing: 0;
          line-height: 16px;
        }
        &:nth-child(2){
          color: #535D5F;
          font-family: Ubuntu;
          font-size: 12px;
          font-weight: 500;
          letter-spacing: 0;
          line-height: 13px;
          text-transform: uppercase;
        }
      }
    }
    .download_file{
      text-decoration: none;
    }
  }
 }
   
   }
  
  }
   @include paysage-phone{
    box-sizing: border-box;
    margin: 0;
     padding:unset !important ;
     position: absolute !important;
     z-index: 1000 !important;
     top: 0 !important;
     left: 0 !important;
     bottom: 0 !important;
     right: 0 !important;
     height: auto !important;
     height: 100vh !important;
     width: 100%;
     border: none;
     background-color: $black;
     .k-player{
      flex-direction: column !important;
        .header{
          height: 100vh !important;
       }
       .footer{
        display: none !important;
       }
     }
       
  
  }
 }
}