@import '../../../../../assets/colors.scss';
@import '../../../../../assets/dimens';

.popup-wrappers {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  opacity: 0;
  background-color: $codGray60;
  transition: z-index, opacity 0.1s ease-in;
  box-sizing: border-box;

  .popups {
    width: 780px;
    display: flex;
    flex-direction: column;
    padding: 16px;
    background-color: $white;
    border-radius: 5px;
    box-shadow: 0 3px 4px 0 rgba(10, 15, 44, 0.2), 0 4px 16px 0 rgba(104, 129, 151, 0.15),
      0 8px 26px 0 rgba(0, 0, 0, 0.15);

    @include phone {
      margin: 0 10px !important;
      padding: 0 !important;
    }

    @include tablette {
      width: 100%;
      margin: 0 32px;
    }

    .close {
      width: 44px;
      height: 44px;
      align-self: flex-end;
      padding: 12px;
      font-size: 20px;
      font-weight: bold;
      color: $stormDust;
      cursor: pointer;
    }

    .form {
      width: 100%;
      height: 100%;
      padding: 0 16px 16px 16px;

      @include phone {
        padding: 0 !important;
      }

      label {
        color: $codGray10;
        font-family: Ubuntu;
        font-size: 16px;
        letter-spacing: 0;
        line-height: 16px;
        margin: 10px 0;
      }

      .input {
        width: 100%;
        border: 1px solid $jungleGreen2;
        border-radius: 23.5px;
        background-color: $white;
        padding: 12px 24px;

        @include phone {
          padding: 14px 12px !important;
        }

        &:focus {
          outline: none;
        }

        &::placeholder {
          color: #b1b1b1;
          font-family: Ubuntu;
          font-size: 13px;
          letter-spacing: 0;
          line-height: 24px;
        }
      }

      input {
        height: 48px;
      }

      textarea {
        resize: none;
        width: 100%;
        min-height: 48px;
        max-height: 120px;

        @include phone {
          min-height: unset !important;
          max-height: unset !important;
          height: 150px !important;
        }
      }

      .form-container {
        padding: 0 80px;
        margin-bottom: 16px;

        @include phone {
          padding: 0 24px !important;
          margin-bottom: unset !important;
        }

        @include tablette {
          padding: 0 30px;
        }

        .profile-info,
        .avatar-info {
          width: 100%;
          color: $codGray50;
          font-size: 18px;
          font-weight: bold;
          line-height: 24px;
          padding-bottom: 8px;
        }

        .profile-content {
          width: 100%;
          margin-bottom: 16px;

          .field {
            position: relative;
            width: 100%;
            display: flex;
            flex-direction: column;
            margin-bottom: 8px;
          }
        }

        .avatar-container {
          width: 100%;
          margin-bottom: 16px;

          @include phone {
            margin-bottom: 0 !important;
          }

          .message {
            font-family: Ubuntu;
            font-size: 14px;
            letter-spacing: 0;
            line-height: 16px;
            margin: 10px 0;

            &.error {
              color: $red;
            }

            &.success {
              color: $green-500;
            }
          }

          p {
            font-family: Ubuntu;
            font-size: 14.5px;
            line-height: 18px;
            color: $codGray50;
            font-weight: 600;
            padding-bottom: 5px;
          }

          .avatar-items {
            #photo {
            }

            .item {
              position: absolute;
              visibility: hidden !important;
              height: 140px;
              width: 140px;
              background-color: $mystic;
              margin: 16px 0;
              border-radius: 35px;
              position: relative;
              display: flex;
              justify-content: center;
              align-items: center;
              cursor: pointer;

              .item-image {
                width: 35px;
                height: 35px;
                border-radius: 100%;
                border: 2px solid $jungleGreen2;
                position: relative;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                padding: 5px;

                i {
                  color: $jungleGreen2;
                }
              }
            }
          }

          .avatar-exist {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 120px;
            width: 120px;
            margin: 16px 0;
            display: flex;

            @include phone {
              height: 100px !important;
            }

            .editIcon {
              position: absolute !important;
              color: $green-500;
              background: $white;
              bottom: 0px;
              right: 0;
              box-shadow: 0px 0px 22px -1px #494747;
              padding: 8px;
              border-radius: 50px;
              cursor: pointer;
            }

            .header {
              object-fit: cover;
              height: 100%;
              width: 100%;
              border-radius: 50%;
            }
            .noAvatar {
              height: 100%;
              width: 100%;
              background-color: $mystic;
              border-radius: 50%;
              display: flex;
              justify-content: center;
              align-items: center;
              cursor: pointer;

              i {
                color: rgb(88, 88, 88);
                font-size: 35px;
              }
            }

            img {
              object-fit: cover;
              width: 100% !important;
              height: 100% !important;
              cursor: pointer;
            }

            .avatar-footer {
              height: 120px;
              width: 120px;
              position: relative;
              display: flex;
              justify-content: center;
              align-items: fle;
              z-index: 1;

              div {
                width: 37px;
                height: 37px;
                display: flex;
                justify-content: center;
                align-items: center;
                position: relative;

                i {
                  color: $green-500;
                  width: 100%;
                  height: 100%;
                  position: absolute;
                  margin-bottom: -12px;
                  margin-right: -12px;
                }
              }
            }
          }
        }
      }

      .action-btn {
        display: flex;
        justify-content: flex-end;

        .button {
          width: 22.5%;
        }

        @include phone {
          padding-bottom: 16px !important;
          padding-right: 16px !important;

          .button {
            width: 30%;
          }
        }
      }
    }
  }

  &.shown {
    z-index: 100;
    opacity: 1;
  }

  &.hidden {
    display: none;
    transition: none;
    z-index: -1;
  }
}
