@import "../../../../../assets/colors";
@import "../../../../../assets/dimens";

.project-item {
  display: flex;
  min-width: 270px;
  flex-direction: column;
  margin-top: 24px;
  flex: 1;
  background-color: $white;
  border-radius: 2px;
  box-shadow: 0 3px 4px 0 rgba(10, 15, 44, 0.2), 0 4px 16px 0 rgba(104, 129, 151, 0.15), 0 8px 26px 0 rgba(0, 0, 0, 0.15);
  overflow: hidden;

  .container {
    display: flex;
    flex-direction: column;
    margin: 10px 24px 0;

    .header {
      display: flex;
      flex-direction: row-reverse;
      align-items: center;
      cursor: pointer;
      margin-bottom: 15px;

      @include width800px {
        flex-direction: column-reverse;
        justify-content: center;

        .separator {
          visibility: hidden;
        }
      }

      .title {
        flex: 12;
        color: $codGray;
        font-size: 24px;
        font-weight: bold;
        line-height: 32px;

        @include width800px {
          width: 250px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          flex: initial;
          margin: 14px 0;
          text-align: center;
        }
      }

      .state {
        height: 15px;
        width: 15px;
        background-clip: content-box;
        border: 1px solid $bombay;
        border-radius: 15px;
        padding: 2px;
        margin-right: 10px;

        &.comming {
          background-color: $treePoppy;
        }

        &.validated {
          background-color: $atlantis;
        }

        &.finished {
          background-color: $red;
        }
      }

      .status {
        display: flex;
        align-items: center;

        p{
          color: $doveGray;
          &:first-of-type {
            margin-right: 10px;
          }
        }

      }
      .middle-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex: 6;

        @include width800px {
          flex: initial;
        }

        .mini-icon-container {
          width: 135px;

          .mini-icon {
            width: 100%;
          }

          &.challenge {
            height: 55px;

            .mini-icon {
              height: 100%;
            }
          }
        }
      }

      .separator {
        height: 40px;
        margin-right: 20px;
        border: none;
        border-right: 1px solid rgba($black, 0.4);
      }

      .type {
        flex: 1;
        font-size: 12px;
        font-weight: 500;
        line-height: 16px;

        @include width800px {
          font-size: 13px;
        }
      }

      .pie {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 75px;
        width: 100px;
        box-sizing: border-box;
        padding: 20px;

        p {
          position: absolute;
          font-size: 12px;
          color: $abbey;
        }

        i {
          position: absolute;
          top: 10px;
          right: 0;
          color: $codGray;
        }
      }

      i {
        margin-right: 10px;
        color: $stormDust;
        transition: transform 0.2s;

        &:hover {
          transform: scale(1.6);
        }
      }
    }
  }

  .separator {
    border: none;
    border-bottom: 1px solid rgba($black, 0.2);
  }

  .info-list {
    display: flex;
    flex-wrap: wrap;
    margin-top: 20px;
    overflow-y: hidden;
    position: relative;
    transition: max-height 0.27s ease-in;

    > .wrapper {
      display: flex;
      flex-direction: row;

      .role {
        flex: 1;
        padding-right: 42px;
        padding-left: 16px;
        margin-bottom: 16px;
        display: flex;
        flex-direction: column;
        border-right: 1px solid rgba($black, 0.3);

        .name {
          font-size: 13px;
          font-weight: bold;
          letter-spacing: 0;
          line-height: 18px;
          margin-bottom: 5px;
        }

        .users {
          display: flex;
          flex-wrap: wrap;
        }

        &.supervisors {
          flex: 2;

          #userItem {
            width: 50%;
          }
        }
      }

      &:first-of-type {
        flex: 3;
      }

      &:last-of-type {
        flex: 2;

        .role:last-of-type {
          border-right: none;
        }
      }
    }
  }

  .more-container {
    display: flex;
    justify-content: flex-end;
    margin: 10px 0;

    .more {
      cursor: pointer;
      display: none;
      width: 60px;
      color: $white;
      font-size: 8px;
      background-color: $bondiBlue;
      padding: 4px 8px;
      border-radius: 4px;
      text-align: center;

      @include width800px {
        display: inline-block;
      }
    }
  }
}

@media screen and (max-width: 1100px) {
  .project-item > .container .info-list {
    max-height: 100px;
    flex-direction: column;
    flex-wrap: nowrap;

    > .wrapper {
      .role {
        &.supervisors {
          flex: 1;
        }

        &:last-of-type {
          border-right: none;
        }
      }

      &:last-of-type {
        .role {
          #userItem {
            width: 50%;
          }

          &:first-of-type {
            padding-left: 0;
          }
        }
      }

      &.full {
        .role {
          #userItem {
            width: 25%;
          }
        }
      }
    }

    &.expanded {
      max-height: 1100px;
    }
  }
}

@media screen and (max-width: 780px) {
  .project-item > .container .info-list > .wrapper {
    max-height: 200px;
    flex-direction: column;

    .role {
      padding: 0;
      border-right: none;

      .users {
        flex-direction: column;

        #userItem {
          width: 100%;
          margin-right: 0;
        }
      }
    }
  }
}
