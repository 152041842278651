@import '../../../assets/colors';
@import '../../../assets/dimens';

.links {
  .list {
    background-color: $white;
    margin: 70px 50px 0 50px;
    padding: 28px ;
    min-height: 200px;
    position: relative;

    @include phone {
      margin: 35px 16px 0 16px;
      padding: unset;
    }

    .captions {
      display: flex;
      margin-bottom: 28px;
      margin-left: 5px;

      p {
        font-size: 12px;
        color: $doveGray;
      }

      p:nth-child(2) {
        margin-left: 15px;
      }

      @include phone {
        margin-bottom: 8px;
      }
    }

    .no-link {
      text-align: center;
      margin: 50px;
      font-size: 12px;
      color: #696969; 
    }
}

  .buttons {
    box-shadow: 0px -16px 15px -7px rgba($black, 0.12);
    background-color: $white;
    height: 85px;
    margin: 0 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 25px;
    z-index: 1;
    position: relative;

    @include phone {
      margin: 0 24px;
    }

    .btn {
      height: 40px;
      width: 158px;
    }
  }
}