@import "../../../../../assets/colors";

#userItem {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-right: 10px;

  .user-item-name {
    width: 100%;
    display: block;
    font-size: 13px;
    white-space: pre-line;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .user-item-contact {
    margin-top: 8px;
    margin-bottom: 32px;
    cursor: pointer;

    i {
      color: $bondiBlue;
      position: relative;

      .user-phone {
        display: none;
        position: absolute;
        bottom: -4px;
        left: 50%;
        padding: 5px 10px;
        font-size: 13px;
        color: $white;
        background-color: $bondiBlue;
        border-radius: 3px;
        transform: translate(-50%, 100%);

        &.displayed {
          display: flex;
        }
      }
    }
  }

  div {
    display: flex;
  }
}
