@import "../../../assets/colors";

.button {
  padding: 10px 16px;
  text-align: center;
  cursor: pointer;
  border-radius: 100px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: .1s ease-in background-color;
  color: $white;

  span {
    font-size: 14px;
    font-weight: 500;
    font-family: 'Ubuntu';
    text-align: center;
    display: flex;
    align-items: center;
    .material-icons {
        color: $white;
        margin: 0 8px;
        padding: 0;
      }
    &.with-icon {
      margin: 0 0 0 6px;
    }
  }

  

  &.orange {
    background-color: $flamingo;
    &:hover {
      background-color: lighten($treePoppy, 10);
    }
  }

  &.green {
    background-color: $mountainMeadow;
    &:hover {
      background-color: lighten($mountainMeadow, 5);
    }
  }
  &.green-500 {
    background-color: $green-500;

    &:hover {
      background-color: lighten($green-500, 5);
    }
  }

  &.gray {
    background-color: $stormDust;
    &:hover {
      background-color: lighten($stormDust, 10);
    }
  }

  i {
    margin-left: auto;
  }

  &.disabled {
    background-color: $bombay;
    &:hover {
      background-color: $bombay;
    }
    cursor: not-allowed;
  }
}
