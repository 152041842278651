@import '../../../assets/colors';
@import '../../../assets/dimens';

#navigation {
  height: 80px;

  #fixed-wrapper {
    z-index: 100;
    display: flex;
    background-color: $white;
    height: 80px;
    padding: 0 38px;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0 0 5px rgba(148, 105, 105, 0.5);
    overflow: hidden;
    box-sizing: border-box;
    position: fixed;
    width: 100%;
    @include phone {
      height: 45px !important;
      padding: 0 !important;
    }
    #menu {
      .material-icons.menu {
        color: $bondiBlue;
      }
    }
    .logo-group {
      display: flex;
      align-items: center;
    }
    .logo-epa {
      width: 200px;
      cursor: pointer !important;
      @include phone {
        display: flex !important;
        width: 100px !important;
      }
    }
    .logo-epa-tablette {
      width: 200px;
      cursor: pointer !important;
      @include phone {
        display: flex;
      }
      @include tablette {
        display: none;
      }
    }
    .dropdown-menu {
      .box{
      background-color: white;
        p {
          color: #00A0AF;
          margin-right: 3px;
          background-color: white;
        }
      }
      .list {
        background-color: white;
        position: fixed;
        max-height: 100%;
        padding: 4px;
        overflow: visible;
        p {
          color: #00A0AF;
        }
      }
    }

    .mini-name,
    .project-name {
      @include phone {
        margin-right: unset;
      }
      @include tablette {
        margin-right: unset;
      }
    }

    .mini-name,
    .project-name-input {
      background: $white;
      color: $bondiBlue;
      font-size: 27px;
      font-weight: 500;
      line-height: 18px;
      &.highlight {
        @include tablette {
          margin-right: unset;
          margin: 0 !important;
          height: 100%;
        }
        @include phone {
          margin: 0 !important;
          font-size: 12px;
        }
      }
    }

    .disabled {
      pointer-events: none;
    }

    .icons {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: row;
      a {
        text-decoration: none;
      }
      .tool-box {
        display: flex;
        align-items: center;
        padding: 8px 16px;
        margin: 0 8px;
        color: $bondiBlue;
        font-size: 16px;
        cursor: pointer;
        @include phone {
          display: none;
        }
      }
      .tool_box_icon {
        background: $bondiBlue;
        color: white;
        padding: 5px;
        font-size: 22px;
        border-radius: 50px;
        cursor: pointer;
        display: none;
        @include phone {
          display: block;
        }
      }
      .badge-wrapper {
        position: relative;

        > p {
          width: 16px;
          height: 16px;
          position: absolute;
          top: 0;
          right: 0;
          color: $white;
          font-family: Ubuntu;
          font-size: 9px;
          letter-spacing: 0;
          line-height: 15px;
          text-align: center;
          background: $red;
          border-radius: 50%;
          border: 1px solid $white;
          transform: translate(50%, 0);
        }
      }
    }

    .material-icons {
      &:not(.menu) {
        margin-left: 24px;
        color: $bondiBlue;
        cursor: pointer;

        @include phone {
          margin-left: unset;
          margin: 5px !important;
          font-size: 20px !important;
        }

        @include tablette {
          margin-left: 15px;
        }
      }
    }

    .material-icons.isOrange {
      color: $flamingo;
    }

    .indicator {
      width: 24px;
      height: 24px;
      position: absolute;
      bottom: 0;
      right: calc(38px + 4 * 24px);
      background-color: $flamingo;
      transform: rotate(45deg) translate(50%, 50%);
      transform-origin: center;
    }

    > .items {
      flex: 1;
      display: flex;
      align-items: center;
      justify-items: center;
      margin: auto;
      color: $white;

      li {
        display: flex;
        align-items: center;
        padding: 8px 16px;
        margin: 0 8px;
        color: $bondiBlue;
        font-size: 16px;
        cursor: pointer;

        &:hover {
          color: $codGray;
        }

        &.is-current {
          cursor: initial;
          color: $codGray;
        }
      }
    }

    #menu {
      display: none;
      @include phone {
        display: flex;
      }
      @include tablette {
        display: none;
      }

      > i {
        width: 72px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        color: $codGray;
        transition: 0.2s ease-in background-color;
        cursor: pointer;

        &.menu {
          left: 0;
          top: 17px;
        }
      }

      .wrapper {
        width: 320px;
        height: 100%;
        position: fixed;
        top: 95px;
        left: -320px;
        opacity: 0;
        background-color: $white;
        color: $white;
        transition: 0.3s ease-in all;
        border-top: 1px solid rgba(black, 0.08);

        &.displayed {
          left: 0;
          opacity: 1;
          box-shadow: 0 5px 5px rgba(0, 0, 0, 0.5);
          border-top: 1px solid rgba($black, 0.08);
          top: 45px;
          width: 100%;
        }

        > .items {
          margin-left: auto;
          display: flex;
          flex-direction: column;
          color: $white;

          li {
            font-size: 18px;
            padding: 0 28px;
            height: 60px;
            display: flex;
            align-items: center;
            color: $bondiBlue;
            cursor: pointer;

            transition: 0.2s ease-in background-color;

            &:hover {
              background-color: rgba($bondiBlue, 0.7);
              color: $white;
            }

            &.is-current {
              cursor: initial;
              color: $white;
              background-color: rgba($bondiBlue, 0.7);
            }
          }

          li i.material-icons {
            flex: 0;
            display: inline-block;
            margin: 0 12px 0 0;
            @include phone {
              font-size: 16px !important;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 992px) {
  #navigation {
    height: 70px;

    #fixed-wrapper {
      padding: 0 16px;
      height: 70px;

      .logo-epa {
        display: flex;
        //@at-root width: 120px;
      }

      .mini-name,
      .project-name-input {
        font-size: 4vw;
        padding: 0;
      }

      .items {
        display: none;
      }

      .highlight {
        margin-left: 12px;
      }

      .indicator {
        position: absolute;
        right: calc(24px * 4 + 24px);
      }

      .less-margin {
        margin-right: initial;
      }

      .menu-burger {
        display: flex;
      }

      #menu {
        display: flex;

        .wrapper {
          top: 62px;
        }
      }
    }
  }
}
