@import '../../../../../assets/colors';

.mini-item {
  min-width: 270px;
  display: flex;
  flex-direction: column;
  margin-top: 24px;
  background-color: #ffffff;
  border-radius: 2px;
  box-shadow: 0 3px 4px 0 rgba(10, 15, 44, 0.2), 0 4px 16px 0 rgba(104, 129, 151, 0.15), 0 8px 26px 0 rgba(0, 0, 0, 0.15);

  .column {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .line {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .header {
    padding: 24px;

    > .wrapper .status{
      display: flex;
      align-items: center;
      p{
        color: $doveGray;
        &:first-of-type {
          margin-right: 10px;
        }
      }
    }

    .title {
      flex: 12 1;
      color: $codGray;
      font-size: 24px;
      font-weight: bold;
      line-height: 32px;
    }

    .state {
      height: 15px;
      width: 15px;
      background-clip: content-box;
      border: 1px solid $bombay;
      border-radius: 15px;
      padding: 2px;
      margin-right: 10px;

      &.comming {
        background-color: $treePoppy;
      }

      &.validated, &.running {
        background-color: $atlantis;
      }

      &.finished {
        background-color: $red;
      }
    }

    > .line {
      flex: 9;
    }

    img {
      width: 135px;
    }

    .vertical-separator {
      width: 1px;
      height: 40px;
      background: $stormDust;
      margin: 0 20px 0 16px;
    }

    .column {

      .date {
        .label {
          color: $doveGray;
          font-size: 12px;
          letter-spacing: 0;
          line-height: 12px;
          text-align: right;
        }

        .value {
          margin-left: 10px;
        }

      }

      .date .value,
      .place {
        color: black;
        font-size: 13px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 16px;
      }
      
    }

  }

  .detail {
    .summary {
      padding: 16px 24px;

      .value {
        color: $shark;
        font-size: 13px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 16px;
      }

      i {
        margin: 0 16px;
        font-size: 14px;
        color: $sorbus;
      }

      .label {
        margin-right: 40px;
        color: $doveGray;
        font-size: 12px;
        letter-spacing: 0;
        line-height: 12px;
      }

      .info {
        flex: 1;
      }

      .action {
        cursor: pointer;

        .label {
          color: black;
          font-size: 13px;
          letter-spacing: 0;
          line-height: 18px;
          text-align: right;
        }

        i {
          font-size: 14px;
          color: $rollingStone;
          transition: transform 0.3s ease;
        }

        &.developped {
          i {
            transform: rotate(90deg);
          }
        }
      }
    }

    .collapse-wrapper {
      max-height: 0;
      overflow: hidden;
      transition: max-height 0.3s ease;

      &.developped {
        max-height: 1500px;
      }
    }

    .rooms {
      .room {
        margin-bottom: 1px;
        padding: 16px 28px;
        background: $wattle20;
        cursor: pointer;

        &:last-of-type {
          margin-bottom: 0;
        }

        > .line {
          flex: 1;
        }

        .label {
          margin-right: 60px;
          color: black;
          font-size: 13px;
          font-weight: 500;
          letter-spacing: 0;
          line-height: 16px;
        }

        i {
          margin-right: 12px;
          color: $bondiBlue;

          &.chevron {
            font-size: 16px;
            color: $rollingStone;
          }
        }

        .students,
        .code,
        .password {
          color: $doveGray;
          font-size: 12px;
          letter-spacing: 0;
          line-height: 12px;
        }

        .code {
          margin-right: 24px;
        }

        .intro {
          flex: 55;
        }

        .access {
          flex: 45;
        }
      }
    }

    .contacts {
      align-items: stretch;

      .vertical-separator {
        width: 0;
        border-right: 1px dashed $alto;
      }

      .column {
        flex: 1;
        justify-content: flex-start;
        padding: 24px;

        .title {
          height: 24px;
          font-size: 13px;
          font-weight: bold;
          letter-spacing: 0;
          line-height: 18px;
        }

        .school-item {
          border-right: none;

          .title {
            display: none;
          }
        }

        .partner-item {
          margin-bottom: 16px;

          p {
            font-size: 13px;
            line-height: 18px;
            text-overflow: ellipsis;
            overflow: hidden;
          }
        }
      }
    }
  }

  .separator {
    width: calc(100% - 48px);
    height: 1px;
    margin: 0 24px;
    background: $stormDust;
  }
}

@media screen and (max-width: 780px) {
  .mini-item {

    .header {
      flex-direction: column;

      .vertical-separator {
        margin: 0 8px 0 0;
      }
    }

    .summary {
       > .info {
         flex-direction: column;
         align-items: flex-start;

         .line {
           margin-bottom: 8px;
         }

         .value {
           width: 18px;
         }

         .label {
           margin-right: 0;
         }
       }

       .action {
         margin-left: 8px;

         .label {
           margin-right: 8px;
         }

         i {
           margin: 0;
         }
       }
    }

    .contacts {
      flex-wrap: wrap;
    }

    .rooms {

      .room {
        padding: 16px;

        > .line {
          flex: 1;
          flex-direction: column;
          align-items: flex-start;

          .intro {
            margin-bottom: 8px;
          }
        }
      }
    }
  }
}
