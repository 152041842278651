@import "../../../../assets/colors";
.footer-item {
  display: flex;
  background-color: rgba(208,223,93,0.2);
  height: 48px;
  align-items: center;
  padding: 0 10px;

  >div {
    display: flex;

    p {
    color: $doveGray;
    font-size: 12px;
    line-height: 12px;
      margin: 0 12px;
    }

    img {
      margin-left: 14px;
    }
  }
}
