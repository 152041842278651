@import '../../../../../../assets/colors';
* {
  box-sizing: border-box;
}
.card-success {
  transform: scale(1);
  transition: all 0.3s;
  &:hover {
    transform: scale(1.03);
  }
  .body {
    cursor: pointer;
    padding-top: 16px !important;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 8px;
    height: 135px;
    width: 160px;
    border-radius: 5px;
    background-color: $white;
    box-shadow: 0 3px 4px 0 rgba(10, 15, 44, 0.2), 0 4px 16px 0 rgba(104, 129, 151, 0.15),
      0 8px 26px 0 rgba(0, 0, 0, 0.15);
    h4,
    p {
      color: $codGray50;
      font-family: Ubuntu;
      font-size: 12px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 16px;
      text-align: center;
      padding: 5px 5px 0 5px;
    }
    .actions {
      display: flex;
      justify-content: space-between;
      align-items: center;
      p {
        color: $codGray50;
        font-family: Ubuntu;
        font-size: 12px;
        font-weight: 500;
        text-align: center;
      }
      i {
        height: 25px;
        width: 15px;
        margin-right: 10px;
        color: $nevada;
      }

      .btn {
        i {
          color: $green-500;

          &.fill-1 {
            height: 12px;
            width: 15px;
            color: $codGray50;
          }
        }
      }
      .medias {
        display: flex;
      }
    }
  }
}
.unachevedsuccess-item {
  * {
    box-sizing: border-box;
  }
  height: 230px;
  margin: 5px;
  width: 160px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 5px;
  cursor: pointer;
  background-color: $white;
  .header-content {
    height: 50%;
    width: 100% !important;
    background-color: transparent;
    border-radius: 5px;
    img {
      width: 100% !important;
      height: 100% !important;
      object-fit: contain;
      border-radius: 5px;
    }
  }
  .success-detail {
    height: 55%;
    width: 100%;
    border-radius: 5px;
    background-color: $white;
    box-shadow: 0 3px 4px 0 rgba(10, 15, 44, 0.2), 0 4px 16px 0 rgba(104, 129, 151, 0.15),
      0 8px 26px 0 rgba(0, 0, 0, 0.15);
    position: absolute;
    left: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-size-adjust: auto;
    -webkit-text-size-adjust: auto;
    h4 {
      color: $codGray50;
      font-family: Ubuntu;
      font-size: 12px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 16px;
      text-align: center;
      padding: 5px 5px 0 5px;
    }

    .actions {
      display: flex;
      justify-content: space-between;
      flex-wrap: nowrap;
      padding: 8px;
      position: relative;

      i {
        height: 25px;
        width: 15px;
        margin-right: 10px;
        color: $nevada;
      }
      .medias {
        width: 107px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
      }
      .deleteIcon {
        color: $green-500 !important;
        padding: 17px;
        background: rgb(235, 235, 235);
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 !important;
        border-radius: 50px;
        margin-right: 10px !important;
        cursor: pointer;
      }

      .btn {
        i {
          color: $green-500;

          &.fill-1 {
            height: 12px;
            width: 15px;
            color: $codGray50;
          }
        }
      }
    }
  }
}
