@import "../../../../../assets/colors";
.contact-item {
  display: flex;
  margin-top: 48px;
  margin-left: 32px;
  margin-right: 32px;

  .resume {
    flex: 1;
    overflow: hidden;
  }

  .item-title {
    margin-bottom: 16px;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 18px;
    color: $codGray;
    overflow: hidden;
  }
}
