@import "../../../assets/colors";

.text-area-wrapper{
  display: flex;
  flex-direction: column;
  justify-content: center;

  .label {
    margin-bottom: 4px;
    color: $lynch;
    font-size: 12px;

    span {
      font-size: 10px;
      margin-left: 4px;

      &.half {
        color: $sun;
      }

      &.full {
        color: $red;
      }
    }
  }

  textarea {
    width: 100%;
    height: 100px;
    display: flex;
    padding: 8px 12px;
    font-size: 13px;
    line-height: 25px;
    font-weight: 600;
    box-sizing: border-box;
    background-color: $athensGray;
    border-bottom: 2px solid $white;
    border-top: none;
    border-left: none;
    border-right: none;
    color: $armadillo;
    outline: none;
    resize: none;

    &.invalid {
      border-bottom: 2px solid $scarlet !important;
    }

    &:disabled {
      height: unset;
      background-color: transparent;
      padding: 0;
    }
  }

  > .error {
    display: none;
    color: $scarlet;
    font-size: 12px;

    &.invalid {
      display: flex;
    }
  }
}
