@import '../../../../assets/colors';
@import '../../../../assets/dimens.scss';

.events {
  background-color:$wildSand;
  flex: 1;
  position: relative;

  .filter {
    display: flex;
    flex-direction: row;
    @include phone {
      width: 100%;
      }
    .option {
      font-size: 18px;
      margin-bottom: 20px;
      color: $codGray;
      padding: 0 24px;
      cursor: pointer;
      @include phone{
        padding: 0 10px;
        font-size: 12px;
        text-align: center;
      }

      &.selected {
        font-weight: 500;
      }
    }
  }

  hr {
    border-top:none;
    border-bottom-width: 1px;
    border-bottom-color:rgba($black, .3);
  }

  ul.meetings-list {
      
    li.meeting-item {
      display: flex;
      justify-content: space-between;
      list-style: none;
      padding: 20px 24px;
      cursor: pointer;

      &:hover {
        background-color: rgba($bondiBlue, .1);
      }

      &.no-cursor {
        cursor: initial;
      }
      
      .title {
        text-transform: capitalize;
        font-weight: 500;
        font-size: 18px;
        display: flex;
        align-items: center;
        margin-bottom: 6px;

        .material-icons {
          margin-right: 12px;
          color: $green-500;
        }
      }

      .description {
        font-size: 13px;
        font-weight: 100;
        line-height: 18px;
        margin-left: 10px;
      }

      .delete {
        color: $bondiBlue;
        cursor: pointer;

        &:hover {
          color: $red;
        }
      }

    }

    .meeting-item:not(:last-child) {
      border-bottom: 1px solid rgba($alto, 1);
    }
  }
  .no_event{
    padding: 16px;
  }
}

