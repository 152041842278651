@import '../../../../assets/colors';
@import '../../../../assets/dimens';

#addBudget {
  .background {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $bondiBlue;
    opacity: 0.9;
    z-index: 10;
  }

  .addBudget {
    position: fixed;
    overflow: scroll !important;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    width: 1000px;
    padding: 0 26px 0 26px;
    background-color: $white;
    z-index: 100;
    box-shadow: 0 3px 4px 0 rgba(10, 15, 44, 0.2), 0 4px 16px 0 rgba(104, 129, 151, 0.15),
      0 8px 26px 0 rgba(0, 0, 0, 0.15);
    height: auto;
    .material-icons {
      border: none;
      outline: none;

      &:hover {
        cursor: pointer;
      }
    }

    input[type='number']::-webkit-inner-spin-button,
    input[type='number']::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    .section {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid #d1d1d1;
      padding: 26px 0;

      .operation,
      .account {
        flex: 1;
      }

      .type {
        flex: 2;
        max-width: 426px;
      }

      .account {
        margin: 0 48px 0 48px;
      }

      .description {
        flex: 3;
        margin-right: 48px;
      }

      .currencyAmount {
        flex: 1;
      }

      .dates {
        flex: 2;
        display: flex;
        justify-content: flex-end;

        .date {
          display: flex;
          flex-direction: column;
          margin-left: 48px;

          > span {
            color: $lynch;
            font-size: 12px;
            margin-bottom: 6px;
          }

          .input-wrapper {
            width: 100%;
            display: flex;
            flex-direction: row;
            align-items: center;
            position: relative;
            box-sizing: border-box;
            border-bottom: 2px solid $white;
            background-color: $athensGray;

            &.disabled {
              pointer-events: none;
            }

            &.invalid {
              border-bottom: 2px solid $scarlet;
            }

            input {
              height: 48px;
              width: 100%;
              font-size: 16px;
              box-sizing: border-box;
              border: none;
              background-color: transparent;
              cursor: pointer;
              -webkit-text-fill-color: $black;

              &:focus {
                outline: none;
              }
            }
          }
        }
      }
    }

    .last {
      justify-content: flex-end;
      border: none;

      .cancel,
      .save {
        width: 200px;
        margin-left: 24px;
        height: 40px;
      }

      .cancel:hover {
        background-color: $red;
      }
      .save:hover {
        background-color: $mountainMeadow;
      }
    }
    .divider {
      margin: 0 16px;
    }
  }

  @media screen and (max-width: 1000px) {
    .addBudget {
      width: 90%;
      height: 90%;
      overflow: hidden;

      .section {
        &:not(.header) {
          flex-direction: column;
        }

        .operation,
        .account,
        .type,
        .description,
        .currencyAmount,
        .switch-item,
        .dates,
        .DayPickerInput,
        .save,
        .cancel {
          width: 100%;
        }

        .account,
        .description {
          margin: 0;
        }

        .operation,
        .account,
        .description,
        .switch-item,
        .operationDate {
          margin-bottom: 36px !important;
        }

        .instantAction {
          align-items: left;
        }

        .cancel {
          margin-bottom: 20px;
        }

        .cancel,
        .save {
          margin-left: 0;
        }

        .dates {
          flex-direction: column;

          .date {
            margin: 0;
          }
        }
      }
    }
  }

  @media screen and (max-height: 700px) {
    .addBudget {
      height: 90%;
    }
  }

  .switch-item {
    flex: 1;

    .switch-input {
      display: none;
    }

    .switch-label {
      position: relative;
      cursor: pointer;
      text-align: right;
      padding: 16px 0 16px 44px;
    }

    .switch-label:before,
    .switch-label:after {
      content: '';
      position: absolute;
      margin: 0;
      outline: 0;
      top: 50%;
      -ms-transform: translate(0, -50%);
      -webkit-transform: translate(0, -50%);
      transform: translate(0, -50%);
      -webkit-transition: all 0.3s ease;
      transition: all 0.3s ease;
    }

    .switch-label:before {
      left: 1px;
      width: 34px;
      height: 14px;
      background-color: rgba(34, 31, 31, 0.26);
      border-radius: 8px;
    }

    .switch-label:after {
      left: 0;
      width: 20px;
      height: 20px;
      background-color: $seashell;
      border-radius: 50%;
      box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.24);
    }

    .switch-input:checked + .switch-label:before {
      background-color: rgba(0, 163, 224, 0.5);
    }

    .switch-input:checked + .switch-label:after {
      background-color: $ceruleanLight;
      -ms-transform: translate(80%, -50%);
      -webkit-transform: translate(80%, -50%);
      transform: translate(80%, -50%);
    }

    .switch-input:checked + .switch-label .toggle--on {
      display: inline-block;
    }

    .switch-input:checked + .switch-label .toggle--off {
      display: none;
    }
  }
}
