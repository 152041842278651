@import "../../../../../assets/colors";

.school-item {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  // min-height: 84px;
  padding-right: 42px;
  padding-bottom: 20px;
  border-right: 1px solid rgba(0, 0, 0, 0.3);

  .title {
    height: 24px;
    font-size: 13px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 18px;
    font-weight: bold;
  }

  p {
    font-size: 13px;
    line-height: 18px;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .contact {
    display: flex;
    align-items: flex-end;
    margin-top: 10px;
    cursor: pointer;

    i {
      cursor: pointer;
      align-items: end;
      color: $bondiBlue;
      position: relative;
      bottom: 10px;
      margin-top: 10px;
    }

    .school-phone-icon {
      .school-phone {
        display: none;
        position: absolute;
        bottom: -4px;
        left: 0;
        padding: 5px 10px;
        font-size: 13px;
        color: $white;
        background-color: $bondiBlue;
        border-radius: 3px;
        transform: translate(0, 100%);

        &.displayed {
          display: initial;
        }
      }
    }
  }
}

@media screen and (max-width: 780px) {
  .school-item {
    padding-right: 0;
    border-right: none;
  }
}