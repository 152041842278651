@import '../../../../assets/colors';

#confirm-popup-background {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  opacity: 0;
  background-color: $codGray60;
  transition: z-index, opacity 0.1s ease-in;

  &.show {
    z-index: 10;
    opacity: 1;
  }

  &.hide {
    display: none;
    transition: none;
    z-index: -1;
  }

  #confirm-popup {
    position: relative;
    background-color: $white;
    transition: z-index, opacity 0.2s ease-in;
    transition-delay: 0.1s;
    box-sizing: border-box;
    padding: 32px 30px 20px 30px;
    border-radius: 3px;

    &.show {
      z-index: 10;
      opacity: 1;
    }

    &.hide {
      z-index: -1;
      opacity: 0;
      transition-delay: 0s;
    }

    i {
      position: absolute;
      top: 6px;
      right: 6px;
      color: $codGray;
      font-size: 16px;
      transition: all .2s ease-in-out;

      &:hover {
        color: $bombay;
        font-size: 20px;
        top: 7px;
        right: 7px;
        cursor: pointer;
      }
    }

    .message {
      box-sizing: border-box;
      margin: 0 0 30px 0;
      color: $codGray;
    }

    .popup-actions {
      display: flex;
      flex-direction: row;
      justify-content: center;

      .edit-button {
        margin: 0 10px;
        width: 125px;
        height: 40px;
      }
    }
  }
}

