@import '../../../assets/colors';
@import '../../../assets/dimens';

#screenHeader {
  display: flex;
  justify-content: space-between;
  padding: 0 50px;
  align-items: center;
  width: 100%;
  height: 64px;
  background-color: $white;
  z-index: 3;
  box-shadow: 0 3px 4px 0 rgba(10, 15, 44, 0.2);
  position: fixed;
  top: 70px;
  @include phone {
  top: 40px !important;
    box-shadow: unset;
  }
  @include tablette {
    padding: 0 16px;
    top: 60px;
  }
  .arrow-back {
    font-size: 20px;
    cursor: pointer;
    transition: transform 0.2s;

    &:hover {
      transform: scale(1.1);
    }
  }

  .links {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    flex: 1;
    padding-right: 12px;
    font-size: 15px;
    font-weight: 500;
    line-height: 18px;
    gap: 20px;
    margin: 10px 0;

    p {
      margin-right: 20px;
      cursor: pointer;
      @include phone {
        margin: 0;
      }
      @include tablette {
        margin: 0;
      }
    }

    p:first-child {
      font-weight: 500;
      @include phone {
        display: flex !important;
      }
      @include tablette {
        display: flex !important;
      }
    }

    .list-filter {
      display: flex;
      align-items: center;
      justify-content: center;
      list-style-type: none;
      
      .btn-filter {
        background-color: $white;
        color: $bondiBlue;
        border: solid 2px $bondiBlue;
        border-radius: 5px;
        padding: 8px 12px;
        gap: 10px;

        &:hover {
          cursor: pointer;
        }

        &.active {
          background-color: $bondiBlue;
          color: $white;
        }
      }

    }
    
  }

  .button-wrapper {
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    .data-collection {
      width: 150px;
      max-height: 40px;
    }

    @include phone {
      display: none;
    }
    @include tablette {
      display: none;
    }
  }
}
