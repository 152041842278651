@import "../../../../assets/colors";
@import "../../../../assets/dimens.scss";
#contacts {
  max-width: 355px;
  display: flex;
  position: fixed;
  top: 80px;
  right: 0;
  bottom: 0;
  background-color: $wildSand;
  box-shadow: 0 3px 4px 0 rgba(10, 15, 44, 0.2), 0 4px 16px 0 rgba(104, 129, 151, 0.15), 0 8px 26px 0 rgba(0, 0, 0, 0.15);
  overflow: scroll;
  transform: translateX(100%);
  transition: transform cubic-bezier(0.075, 0.82, 0.165, 1) 0.3s;
  z-index: 100;

  &.displayed {
    transform: translateX(0);
  }

  > .container {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  @include phone{
     width: 100%;
    max-width: none;
    top: 45px;
  }
}

