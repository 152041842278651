@import '../../../assets/colors';

#room {
  position: relative;
  background: $wildSand;

  .column {
    display: flex;
    flex-direction: column;
  }

  .row {
    display: flex;
    flex-direction: row;
  }

  .navigation {
    width: 100%;
    height: 65px;
    position: fixed;
    top: 80px;
    align-items: center;
    padding: 0 60px;
    background-color: $jungleGreen;
    box-shadow: 0 4px 16px 0 rgba(104, 129, 151, 0.15), 0 8px 26px 0 rgba(0, 0, 0, 0.15);
    z-index: 15;

    i {
      font-size: 28px;
      color: white;
      cursor: pointer;
    }

    .title {
      margin: 0 40px;
      color: white;
      font-size: 16px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 18px;
    }

    .room-picker {
      align-items: center;

      .vertical-separator {
        width: 1px;
        height: 65px;
        background: white;
      }

      .drop-down {
        margin: 0 28px 0 40px;

        .box {
          background: transparent;
          border-bottom: none;

          .current {
            width: 80px;
            margin-right: 56px;
            color: white;
            font-size: 16px;
            font-weight: bold;
            letter-spacing: 0;
            line-height: 22px;
          }

          .arrow {
            border-top: 6px solid white;
          }
        }
      }
    }
  }

  .successes-detail {
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 80px;
    left: 0;
    bottom: 0;
    right: 0;
    background: $wildSand;
    z-index: 50;
    transform: translateX(100%);
    transition: transform 0.3s ease-in;

    &.show {
      transform: translateX(0%);
    }

    .navigation {
      top: 0;
    }
    
    #screenHeader {
      z-index: 25;

      .links .link {
        display: none;
      }

      p {
        color: white;
      }
    }

    .wrapper {
      margin-top: 80px;
      padding: 32px 56px;
      overflow: scroll;
    }

    .panel {
      flex: 1;
      background-color: white;
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.12), 0 4px 8px 2px rgba(0, 0, 0, 0.24);

      .container {
        padding: 24px;

        .titles {
          margin-bottom: 24px;
          color: $doveGray;
          font-size: 12px;
          line-height: 12px;

          .status {
            flex: 1;
          }

          .title {
            flex: 10;
          }

          .earned {
            flex: 5;
          }
        }

        .success {
          height: 64px;
          align-items: center;
          margin-bottom: 8px;
          background-color: $wildSand;

          .information {
            flex: 11;
            align-items: center;

            i {
              flex: 1;
              text-align: center;
              color: $rollingStone;

              &.validated {
                color: $pistachio;
              }
            }

            .label {
              flex: 10;
              padding-right: 24px;
            }
          }

          .validation {
            flex: 5;
            align-items: center;
            justify-content: space-between;

            .react-datepicker-wrapper input {
              font-size: 13px;
              font-weight: 500;
              background-color: $white;
              height: 46px;
              padding: 0 0 0 16px;
              cursor: pointer;
              color: $armadillo;

              &::-webkit-clear-button {
                display: none;
              }

              &::placeholder {
                /* Chrome, Firefox, Opera, Safari 10.1+ */
                color: $armadillo;
                opacity: 1; /* Firefox */
              }

              &:-ms-input-placeholder {
                /* Internet Explorer 10-11 */
                color: $armadillo;
              }

              &::-ms-input-placeholder {
                /* Microsoft Edge */
                color: $armadillo;
              }

              &.disabled {
                cursor: auto;
                color: $lynch;

                &::placeholder {
                  /* Chrome, Firefox, Opera, Safari 10.1+ */
                  color: $lynch;
                  opacity: 1; /* Firefox */
                }

                &:-ms-input-placeholder {
                  /* Internet Explorer 10-11 */
                  color: $lynch;
                }

                &::-ms-input-placeholder {
                  /* Microsoft Edge */
                  color: $lynch;
                }
              }
            }
          }
        }
      }

      .footer {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        height: 86px;
        box-shadow: 0 -8px 8px -6px rgba(0, 0, 0, 0.12), 0 -11px 8px -11px rgba(0, 0, 0, 0.24);

        .button {
          width: 158px;
          height: 40px;
          margin-right: 26px;
        }
      }
    }
  }

  .content {
    margin-top: 65px;
    padding: 40px 54px;

    .primary {
      width: 65%;
      margin-right: 16px;

      .presentation {
        margin-bottom: 16px;
        background-color: white;
        box-shadow: 0 4px 16px 0 rgba(104, 129, 151, 0.15), 0 8px 26px 0 rgba(0, 0, 0, 0.15);

        .info {
          padding: 48px 38px;

          img {
            width: 220px;
          }
          
          .label {
            color: $jungleGreen;
            font-size: 34px;
            letter-spacing: 0;
            line-height: 37px;
          }

          .subject {
            margin-top: 38px;
            color: black;
            font-size: 20px;
            font-weight: 500;
            letter-spacing: 0;
            line-height: 32px;
          }
        }

        .carousel {
          width: 100%;
          align-items: center;
          border: 1px solid $dustyGray;
          border-radius: 2px;
          background-color: $codGray;

          .arrow {
            i {
              padding: 12px;
              font-size: 30px;
              color: $jungleGreen;
              cursor: pointer;
            }

            &.left {
              i {
                transform: rotate(180deg);
              }
            }
          }

          .videos-wrapper {
            width: calc(100% - 2 * 54px);

            .videos {
              width: 100%;
              overflow: hidden;

              .video {
                min-width: 100%;
                margin-top: 24px;

                p {
                  margin-bottom: 24px;
                  color: white;
                  font-size: 24px;
                  font-weight: bold;
                  letter-spacing: 0;
                  line-height: 32px;
                }

                iframe {
                  width: 100%;
                }
              }
            }

            .bubbles {
              justify-content: center;
              padding: 18px;

              .bubble {
                width: 12px;
                height: 12px;
                margin: 0 12px;
                border-radius: 50%;
                background: $alto;
                cursor: pointer;

                &.selected {
                  background: $jungleGreen;
                }
              }
            }
          }
        }
      }

      .successes {
        padding: 32px 28px;
        background-color: white;
        box-shadow: 0 4px 16px 0 rgba(104, 129, 151, 0.15), 0 8px 26px 0 rgba(0, 0, 0, 0.15);

        .header {
          align-items: center;
          margin-bottom: 24px;

          > .label {
            flex: 1;
            color: $codGray;
            font-size: 24px;
            font-weight: bold;
            letter-spacing: 0;
            line-height: 32px;
          }

          .button-wrapper {
            .button {
              width: 150px;
              max-height: 40px;
            }
          }
        }

        .progress {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 24px;

          .bar {
            flex: 1;
            background-color: $flamingo;
            border-radius: 12px;
            border: 4px solid $mystic;
            height: 22px;

            .inner {
              background-color: $jungleGreen;
              border-radius: 12px 12px 12px 12px;
              height: 100%;
            }
          }
        }

        .success {
          align-items: center;
          margin-bottom: 8px;
          padding: 12px 20px;
          border: 1px solid $alto;
          border-radius: 2px;

          i {
            color: $bombay;

            &.validate {
              color: $citron;
            }
          }

          .label {
            margin: 0 32px;
            font-size: 13px;
            color: black;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          .date {
            font-size: 13px;
            color: $bombay;
            text-align: right;
            font-weight: 100;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
    }

    .secondary {
      flex: 1;
      margin-left: 16px;

      .tools {
        padding: 32px 28px;
        background-color: white;
        box-shadow: 0 4px 16px 0 rgba(104, 129, 151, 0.15), 0 8px 26px 0 rgba(0, 0, 0, 0.15);

        .label {
          margin-bottom: 18px;
          color: $codGray;
          font-size: 24px;
          font-weight: bold;
          letter-spacing: 0;
          line-height: 32px;
        }

        .tool {
          margin-bottom: 8px;
          align-items: center;
          cursor: pointer;
          transition: color 0.1s ease-in-out;

          &:hover {
            color: rgba($bondiBlue, 0.7);

            .fa,
            .fas,
            .fab {
              color: rgba($bondiBlue, 0.7);
            }

            img {
              filter: none;
              opacity: 0.7;
            }

            p {
              color: rgba($bondiBlue, 0.7);
            }
          }

          .fa,
          .fas,
          .fab {
            min-width: 24px;
            font-size: 24px;
            color: $shark;
            text-align: center;
          }

          img {
            width: 24px !important;
            filter: grayscale(100%) contrast(60%) hue-rotate(180deg) brightness(0.25);
          }

          p {
            margin-left: 16px;
            color: $shark;
            font-size: 13px;
            font-weight: 500;
            letter-spacing: 0;
            line-height: 16px;
          }
        }
      }

      .partners {
        margin-top: 20px;

        .label {
          color: $codGray;
          font-size: 18px;
          font-weight: bold;
          letter-spacing: 0;
          line-height: 24px;
        }

        .desc {
          margin-top: 16px;
          color: $armadillo;
          font-size: 13px;
          letter-spacing: 0;
          line-height: 16px;
        }

        .partner {
          margin-top: 32px;
          color: $codGray;
          font-size: 24px;
          font-weight: bold;
          letter-spacing: 0;
          line-height: 28px;
        }
      }

      > .progress {
        padding: 32px 28px;
        background-color: white;
        box-shadow: 0 4px 16px 0 rgba(104, 129, 151, 0.15), 0 8px 26px 0 rgba(0, 0, 0, 0.15);

        .label {
          margin-bottom: 18px;
          color: $codGray;
          font-size: 24px;
          font-weight: bold;
          letter-spacing: 0;
          line-height: 32px;
        }

        .row {
          align-items: center;
        }

        .name {
          flex: 1;
          color: black;
          font-size: 13px;
          letter-spacing: 0;
          line-height: 18px;
        }

        .value {
          color: $abbey;
          font-size: 12px;
          letter-spacing: 0;
          line-height: 16px;
          text-align: right;
        }

        .progress {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 24px;

          &.current {
            .bar {
              background-color: $flamingo;
            }
          }

          .bar {
            flex: 1;
            background-color: $stiletto;
            border-radius: 12px;
            border: 4px solid $mystic;
            height: 22px;

            .inner {
              background-color: $jungleGreen;
              border-radius: 12px 12px 12px 12px;
              height: 100%;
            }
          }
        }
      }

      > div {
        margin-bottom: 20px;
      }
    }
  }
}

@media screen and (max-width: 1000px) {
  #room > .content {
    flex-direction: column;

    .primary {
      width: auto;
      flex: 1;
      margin-right: 0;
    }

    .secondary {
      margin-left: 0;
      margin-top: 24px;
    }
  }
}

@media screen and (max-width: 768px) {
  #room {
    .navigation {
      top: 62px;
      padding: 0 12px;

      .title {
        margin: 0 16px;
      }

      .room-picker {

        .vertical-separator:last-of-type {
          display: none;
        }

        .drop-down {
          margin: 0 24px;

          .box .current {
            margin-right: 16px;
          }
        }
      }
    }

    > .content {
      padding: 24px 8px;
    }

    .successes-detail {
      top: 62px;

      .wrapper {
        padding: 24px 8px;

        .container {
          padding: 8px 0;

          .titles {
            display: none;
          }

          .success {
            height: auto;
            padding: 8px;
            flex-direction: column;

            .information {
              width: 100%;

              i {
                margin-right: 12px;
              }
            }

            .validation {
              width: 100%;
              margin-top: 8px;
            }
          }
        }
      }
    }
  }
}
