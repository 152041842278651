@import "../../../assets/colors";

.input {
  width: 100%;
  position: relative;
  box-sizing: border-box;
  background-color: $white;
  border: 1px solid $geyser;

  &.invalid .wrapper {
    border-bottom: 2px solid $scarlet;
  }

  .wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 16px;
    padding-right: 16px;
    border-bottom: 2px solid $white;

    input {
      height: 48px;
      width: 100%;
      font-size: 16px;
      box-sizing: border-box;
      border: none;
      background-color: transparent;

      &:focus {
        outline: none;
      }
    }

    img {
      cursor: default;
      margin-left: 16px;
      color: $rollingStone;
    }
  }
}

