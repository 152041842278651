@import "../../../assets/colors";

.profile {
  .background {
    width: 100%;
    position: absolute;
    top: 0;
    right: -1px;
    left: -1px;
    z-index: -1;
  }
  
  .container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 32px 5%;
    padding: 64px 0;
    background: $white;

    .form-wrapper {
      width: 570px;
    }
  }
}

@media screen and (max-width: 768px) {
  .profile .container {
    padding: 32px;

    .form-wrapper {
      width: 100%;
    }
  }
}

@media screen and (max-width: 992px) {
  #screenHeader .links p:first-child {
    display: flex;
  }
}
