@import '../../../../assets/colors';
@import '../../../../assets/dimens';
#projectDetail {
  * {
    box-sizing: border-box;
  }
  @include phone {
    padding: 2px !important;
  }
  .negative {
    color: $scarlet;
  }
  .link {
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  .bloc {
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    padding: 0 50px;
    margin-top: 80px;
    @include phone {
      margin-top: 55px !important;
      padding: 0 8px !important;
    }
    @include tablette {
      margin-top: 75px;
      padding: 0 16px;
    }
    .descriptionBoc {
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-between;
      width: 100%;
      background-color: $white;
      border-radius: 5px;
      padding: 16px 8px 16px 28px;
      box-shadow: 0 3px 4px 0 rgba(10, 15, 44, 0.2), 0 4px 16px 0 rgba(104, 129, 151, 0.15),
        0 8px 26px 0 rgba(0, 0, 0, 0.15);
      @include phone {
        padding: 16px !important;
      }
      .description_left {
        display: flex;
        flex-direction: column;
        justify-content: center;
        font-family: 'Ubuntu';
        .title {
          font-size: 24px;
          color: $jungleGreen2;
          font-weight: bold;
          line-height: 32px;
          margin-bottom: 8px;
          @include phone {
            font-size: 18px !important;
          }
          @include tablette {
            font-size: 20px;
          }
        }
        p {
          color: $armadillo;
          font-size: 16px;
          font-weight: 500;
          line-height: 16px;
          @include phone {
            font-size: 12px !important;
            font-weight: 400 !important;
          }
        }
      }
      .description_right {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        @include phone {
          justify-content: space-between;
          align-items: flex-end;
          flex-direction: column-reverse;
        }
        .pictogram {
          width: 90px;
          height: 87px;
          margin: 8px 16px;
          cursor: pointer;
          &.background {
            background-color: $mystic;
          }
          &:hover {
            transform: scale(1.1);
          }
        }
        .pencil {
          display: flex;
          justify-content: center;
          align-items: flex-start;
          padding: 0 8px;
          i {
            cursor: pointer;
            color: $black;
          }
        }
      }
    }
  }

  .main {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    width: 100%;
    padding: 0 50px;
    margin-top: 22px;
    position: relative;
    @include phone {
      padding: 0 8px !important;
      flex-direction: column !important;
      padding: 0 8px !important;
      width: 100%;
    }
    @include tablette {
      padding: 0 16px;
    }
    .successes,
    .planning,
    .tracking {
      display: flex;
      flex-direction: column;
      background-color: $white;
      padding: 26px 32px;
      margin-bottom: 30px;
      transition: width 2s;
      border-radius: 5px;
      box-shadow: 0 3px 4px 0 rgba(10, 15, 44, 0.2), 0 4px 16px 0 rgba(104, 129, 151, 0.15),
        0 8px 26px 0 rgba(0, 0, 0, 0.15);
      @include phone {
        padding: 16px 8px !important;
      }
      h2 {
        font-size: 20px;
        color: $codGray50;
        font-weight: bold;
        line-height: 24px;
        @include phone {
          font-size: 18px;
        }
      }

      hr {
        margin: 25px 0 18px;
        border-color: rgba($alto, 0.25);
      }

      img {
        width: 200px;
        align-self: center;
      }
    }

    .planning {
      display: flex;
      flex-direction: column;
      min-height: 380px;
      max-width: 350px;
      @include tablette {
        max-width: 100%;
      }
      > img {
        margin-top: 48px;
        margin-bottom: 16px;
      }
      > p {
        font-size: 16px;
        text-align: center;
        font-family: 'Ubuntu';
        font-weight: 500;
        line-height: 18px;
        margin-bottom: 48px;
        flex: 1;
      }

      ul.meeting-list {
        flex: 1;
        overflow-y: auto;
        overflow-x: hidden;
        display: flex;
        flex-direction: column;
        &::-webkit-scrollbar {
          width: 10px;
        }
        li.meeting-item {
          list-style: none;

          .title {
            text-transform: capitalize;
            font-weight: 500;
            font-size: 18px;
            display: flex;
            align-items: center;
            margin-bottom: 6px;
            .material-icons {
              color: $green-500;
              margin-right: 12px;
            }
          }

          .description {
            font-size: 13px;
            font-weight: 100;
            line-height: 18px;
            white-space: nowrap;
            max-width: 250px;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
    }

    .detail-header {
      display: flex;
      flex-direction: column;
      padding: 0;
      border-top: none;
      margin-bottom: 16px;

      .manage-bt {
        color: $ceruleanLight;
        font-size: 13px;
        cursor: pointer;
        transition: color 0.4s ease-in-out;

        &:hover {
          color: $black;
        }
      }
    }

    .progress {
      display: flex;
      justify-content: space-between;
      align-items: center;
      @include phone {
        justify-content: center !important;
      }
      .bar {
        flex: 1;
        background-color: $flamingo;
        border-radius: 12px;
        border: 4px solid $mystic;
        height: 22px;
        .inner {
          background-color: $atlantis;
          border-radius: 12px 12px 12px 12px;
          height: 100%;
        }
      }

      .pourcentage {
        color: $abbey;
        font-size: 12px;
        margin-left: 16px;
      }
    }

    .successes,
    .planning {
      margin-right: 32px;
      @include phone {
        margin-right: unset;
      }
      @include tablette {
        margin-right: unset;
      }
      &.no-marge {
        margin-right: 0;
      }
    }

    .successes {
      flex: 2;
      min-width: 489px;
      box-sizing: border-box;
      @include tablette {
        width: 100%;
        flex: unset;
        min-width: unset;
      }
      .detail-header {
        p {
          color: $armadillo;
          font-family: Ubuntu;
          font-size: 15px;
          font-weight: 500;
          letter-spacing: 0;
          line-height: 16px;
          margin-top: 8px;
          margin-bottom: 21px;
        }
      }
      .successes-container {
        display: flex;
        justify-content: center;
        column-gap: 20px;
        height: 280px;
        margin-bottom: 20px;
        @include phone {
          height: auto !important;
          flex-wrap: wrap !important;
          flex-direction: column !important;
          column-gap: unset !important;
          row-gap: 20px !important;
          align-items: center;
          height: auto;
          justify-content: center !important;
        }
        .left,
        .right {
          border-radius: 5px;
          background-color: $green-100;
        }
        .left {
          width: 30%;
          height: 100%;
          padding: 16px 29px 47px 30px;
          display: flex;
          align-items: center;
          flex-direction: column;
          @include phone {
            width: 100% !important;
            padding: 16px 8px !important;
            align-items: unset;
          }
          .title {
            color: $codGray50;
            font-family: Ubuntu;
            font-size: 18px;
            font-weight: bold;
            letter-spacing: 0;
            line-height: 22px;
            margin-bottom: 18px;
            @include phone {
              text-align: center !important;
            }
          }
        }
        .right {
          width: 70%;
          height: 100%;
          display: flex;
          position: relative;
          column-gap: 32px;
          padding: 15px 40px 15px 16px;
          @include phone {
            width: 100% !important;
            padding: 16px 8px !important;
            column-gap: unset;
            flex-wrap: wrap;
            flex-direction: column;
          }
          .label {
            width: 25%;
            height: 100%;
            @include phone {
              width: 100%;
            }

            p {
              margin-bottom: 10px;
            }
            div {
              display: flex;
              flex-direction: column;
              gap: 10px;
              @include phone {
                flex-direction: row;
                justify-content: center;
                align-items: center;
              }
            }
            .texte,
            .others {
              color: $codGray50;
              font-family: Ubuntu;
              font-size: 18px;
              font-weight: bold;
              letter-spacing: 0;
              line-height: 22px;
              padding-top: 1px;
              @include phone {
                text-align: center;
              }
            }
            .number {
              color: $jungleGreen2;
              font-family: Ubuntu;
              font-size: 34px;
              font-weight: bold;
              letter-spacing: 0;
              line-height: 32px;
            }
          }
          .desktop_successes_list {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            gap: 14px;
            align-items: center;
            position: relative;

            @include phone {
              width: 100%;
              position: unset;
            }
            ul {
              display: flex;
              list-style: none;
              justify-content: space-between;
              li {
                display: none !important;
                &.previous,
                &.next {
                  display: flex !important;
                  position: absolute;
                  cursor: pointer;
                  z-index: 1 !important;
                }
                &.previous {
                  left: -25px !important;
                  @include phone {
                    left: 25px !important;
                    bottom: 5px !important;
                  }
                }
                &.next {
                  right: -10px !important;
                  @include phone {
                    right: 25px !important;
                    bottom: 5px !important;
                  }
                }
              }
            }
            .page-count-phone {
              display: none;
              @include phone {
                display: flex;
                position: absolute;
                left: 0;
                right: 0;
                bottom: 12px;
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                font-weight: bold;
              }
            }

            /*  @include phone {
                display: none;
              } */
          }
          .phone_successes_list {
            display: none;
            @include phone {
              display: flex;
              flex-wrap: wrap;
              gap: 10px;
            }
          }
          .phone_success_footer {
            display: none;
            @include phone {
              display: flex;
              padding-top: 30px;
            }
          }
        }
        .view-more {
          display: none;
          @include phone {
            display: flex;
            justify-content: center;
            align-items: center;
            padding-top: 16px;
            cursor: pointer;
            p {
              font-size: 14px;
              font-family: 'Ubuntu';
              font-weight: 400;
            }
          }
        }
      }
      .action-btn {
        display: flex;
        justify-content: flex-end;
        .button {
          @include tablette {
            width: 100% !important;
          }
        }
      }
    }
    .planning,
    .tracking {
      flex: 1;
      .soon {
        font-size: 13px;
        margin-top: 50px;
      }
    }
  }
  .ressources {
    display: flex;
    margin: 0 50px 30px;
    flex-wrap: nowrap;
    gap: 32px;
    @include phone {
      width: 100%;
      margin: 0 !important;
      padding: 0 8px;
      flex-direction: column;
      flex-wrap: wrap;
      grid-gap: unset;
      gap: unset;
      row-gap: 20px;
    }
    @include tablette {
      margin: 0 20px;
    }
    .left {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      background-color: $white;
      border-radius: 5px;
      box-shadow: 0 3px 4px 0 rgba(10, 15, 44, 0.2), 0 4px 16px 0 rgba(104, 129, 151, 0.15),
        0 8px 26px 0 rgba(0, 0, 0, 0.15);
      padding: 6px;
      width: 50%;
      @include phone {
        padding: 8px;
        width: 100%;
      }

      .title {
        padding: 16px;
        @include phone {
          padding: 8px;
        }
        > h2 {
          color: $codGray;
          font-size: 20px;
          font-weight: bold;
          line-height: 24px;
          @include phone {
            font-size: 17px !important;
          }
        }
      }
      .budget {
        padding: 20px;
        display: flex;
        @include phone {
          padding: 10px;
          flex-direction: column;
          row-gap: 10px;
        }
        .budget-item {
          display: flex;
          align-items: center;
          width: 100%;
          font-family: Ubuntu;

          .budget-item-title {
            color: $codGray50;
            font-size: 16px;
            line-height: 18px;
            margin-right: 26px;
            font-weight: 500;
          }
          .budget-item-content {
            color: $codGray50;
            font-size: 24px;
            line-height: 24px;
            font-weight: bold;
            p {
              width: 100%;
            }
          }
        }
      }
      ul.links-list {
        list-style: none;
        display: flex;
        flex-wrap: wrap;
        // min-height: 20px;
        padding: 6px 0;
        li {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          font-size: 13px;
          color: $shark;
          margin: 14px;
          cursor: pointer;
          transition: transform 0.1s ease-in-out;

          &:hover {
            transform: scale(1);
            color: rgba($bondiBlue, 0.7);

            .fa,
            .fas,
            .fab {
              color: rgba($bondiBlue, 0.7);
            }

            img {
              filter: none;
              opacity: 0.7;
            }
          }

          .fa,
          .fas,
          .fab {
            min-width: 20px;
            font-size: 24px;
            margin-right: 10px;
            color: $shark;
            text-align: center;
          }

          img {
            width: 24px !important;
            margin-right: 15px;
            filter: grayscale(100%) contrast(60%) hue-rotate(180deg) brightness(0.25);
          }

          .link-title {
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            width: 120px;
          }
        }
      }

      .manage-bt {
        padding: 10px;
        color: $ceruleanLight;
        transition: color 0.4s ease-in-out;
        display: flex;
        justify-content: flex-end;
        @include phone {
          justify-content: unset;
        }
        .manage-bt-links {
          width: 40%;
          z-index: 2;
          @include phone {
            width: 100% !important;
          }
          @include tablette {
            width: unset;
          }
        }
      }
      &.add-icon {
        position: relative;
        z-index: 2;
        img {
          position: absolute;
          left: 0;
          bottom: 0;
          @include phone {
            display: none;
          }
          @include tablette {
            width: 100%;
          }
        }
      }
    }
    .right {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }

  .data {
    display: flex;
    margin: 30px 50px 0 50px;
    background-color: $white;
    border-radius: 5px;
    box-shadow: 0 3px 4px 0 rgba(10, 15, 44, 0.2), 0 4px 16px 0 rgba(104, 129, 151, 0.15),
      0 8px 26px 0 rgba(0, 0, 0, 0.15);
    position: relative;
    @include phone {
      margin: unset !important;
      margin-top: 16px !important;
      z-index: 2 !important;
    }
    @include tablette {
      width: 100%;
      margin: 20px 0px;
    }
    &.unfilled {
      flex-direction: row;
      padding: 64px 32px;
      align-items: center;
      justify-content: space-between;
      @include phone {
        padding: 16px 8px;
        flex-direction: column;
        width: 96%;
        row-gap: 10px;
      }
      .wrapper {
        display: flex;
        flex-direction: row;

        > p {
          margin-left: 25px;
          color: $codGray;
          font-family: 'Ubuntu', sans-serif;
          font-size: 18px;
          font-weight: bold;
          letter-spacing: 0;
          line-height: 32px;
        }

        .action-wrapper {
          display: flex;
          flex-direction: row;
          align-items: center;
          margin-left: 20px;
          color: $mountainMeadow;
          cursor: pointer;

          p {
            margin-left: 16px;
            font-family: 'Ubuntu', sans-serif;
            font-weight: bold;
            font-size: 18px;
            line-height: 32px;
            letter-spacing: normal;
          }
        }
      }
    }
    &.filled {
      flex-direction: column;
      padding: 30px;
      @include phone {
        padding: 16px !important;
      }
      @include tablette {
        padding: 0px 20px;
      }
      .header {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;

        .label {
          flex: 1;
          color: $codGray50;
          font-family: 'Ubuntu', sans-serif;
          font-size: 20px;
          font-weight: bold;
          letter-spacing: 0;
          line-height: 24px;
        }

        .manage-bt {
          padding: 12px;
          color: $black;
          font-size: 13px;
          transition: color 0.4s ease-in-out;
          cursor: pointer;
        }
      }

      .info {
        margin-top: 6px;

        ul {
          list-style: none;

          li.class {
            display: flex;
            flex-direction: row;
            gap: 25px;
            flex-wrap: nowrap;
            margin-top: 4px;
            margin-bottom: 14px;
            @include phone {
              flex-direction: column;
              gap: unset;
              row-gap: 10px;
              flex-wrap: wrap;
            }
            .wrapper {
              display: flex;
              flex-direction: column;
              padding: 25px;
              background-color: $green-100;
              border-radius: 5px;
              width: 25%;
              @include phone {
                width: 100% !important;
                padding: 15px !important;
              }
              @include tablette {
                padding: 10px;
              }
              .label {
                color: $black;
                font-family: 'Ubuntu', sans-serif;
                font-size: 16px;
                letter-spacing: 0;
                line-height: 18px;
                padding-bottom: 10px;
              }
              .composition {
                display: flex;
                column-gap: 16px;
                .count {
                  display: flex;
                  flex-wrap: nowrap;
                  column-gap: 13px;
                  .item {
                    display: flex;
                    flex-wrap: nowrap;
                    p {
                      font-size: 14px;
                    }
                  }
                }
              }
              .value {
                color: $shark;
                font-family: 'Ubuntu', sans-serif;
                font-size: 16px;
                font-weight: bold;
                letter-spacing: 0;
                line-height: 22px;
                &.number {
                  color: $jungleGreen2;
                  font-size: 24px;
                }
              }

              &.category,
              &.label {
                flex-direction: row;
                padding: 14px 20px 14px 14px;

                .index {
                  width: 40px;
                  height: 40px;
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                  align-items: center;
                  border-radius: 50%;

                  &.class {
                    background: $flamingo;
                    color: $white;
                  }

                  &.time {
                    background: $wattle;
                    color: $nevada;
                  }
                }
              }

              &.category {
                > div {
                  display: flex;
                  flex-direction: column;
                  margin-left: 30px;
                }
              }

              &.label {
                img {
                  margin-right: 8px;
                }

                .index {
                  margin-right: 20px;
                }
              }

              &.label {
                align-items: center;
              }
            }
          }
          li.time {
            display: flex;
            justify-content: flex-end;
            width: 100%;
            flex-direction: row;
            gap: 25px;

            @include tablette {
              justify-content: flex-start;
            }
            .time-container {
              display: flex;
              background-color: $white100;
              border-radius: 5px;
              padding: 25px;
              width: 74.5%;
              gap: 25px;
              @include phone {
                flex-direction: column !important;
                row-gap: 10px !important;
              }
              @include tablette {
                width: 100%;
                padding: 10px;
              }
              .volume {
                display: flex;
                justify-content: space-between;
                width: 30%;
                @include phone {
                  width: 100% !important;
                  flex-direction: column;
                }
                @include tablette {
                  width: 45%;
                }
                .volume-left {
                  width: 40%;
                  @include phone {
                    width: 100% !important;
                    padding: 8px 0;
                  }
                  .label {
                    color: $codGray50;
                    font-family: Ubuntu;
                    font-size: 16px;
                    letter-spacing: 0;
                    line-height: 18px;
                  }
                }
                .volume-right {
                  width: 40%;
                  @include phone {
                    width: 100% !important;
                    display: flex;
                    flex-direction: row-reverse;
                    justify-content: flex-end;
                    align-items: flex-end;
                    margin: 5px 0;
                  }
                  .label {
                    text-align: center;
                    padding: 0 16px;
                    color: $jungleGreen2;
                    font-family: Ubuntu;
                    font-size: 24px;
                    font-weight: bold;
                    letter-spacing: 0;
                    line-height: 32px;
                  }
                }
              }
              .matiere {
                font-family: Ubuntu;
                color: $codGray50;
                font-size: 16px;
                letter-spacing: 0;
                .title {
                  line-height: 18px;
                  margin-bottom: 8px;
                }
                .value {
                  font-weight: bold;
                  line-height: 22px;
                }
              }
            }
          }
        }
      }
    }
  }

  .popup-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    opacity: 0;
    background-color: $codGray60;
    transition: z-index, opacity 0.1s ease-in;

    .popup {
      width: 768px;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 16px;
      background-color: $white;
      border-radius: 5px;
      margin: 15px;
      .close {
        width: 44px;
        height: 44px;
        align-self: flex-end;
        padding: 12px;
        font-size: 20px;
        color: #2c2c2c;
        background: #dfdfdf;
        cursor: pointer;
        border-radius: 50px;
      }

      p {
        margin-top: 32px;
        margin-bottom: 100px;
        padding-left: 84px;
        padding-right: 84px;
        color: #1e1e1e;
        font-family: 'Ubuntu', sans-serif;
        font-size: 24px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 32px;
        text-align: center;
        @include phone {
          padding-left: unset;
          padding-right: unset;
          font-size: 18px;
          line-height: 24px;
        }
      }

      .actions-wrapper {
        width: 100%;
        display: flex;
        flex-direction: row;
        margin-bottom: 42px;
        padding-left: 122px;
        padding-right: 122px;
        @include phone {
          padding-left: unset;
          padding-right: unset;
        }
        .button {
          flex: 1;
          margin-left: 8px;
          margin-right: 8px;
        }
      }
    }

    &.shown {
      z-index: 100;
      opacity: 1;
    }

    &.hidden {
      display: none;
      transition: none;
      z-index: -1;
    }
  }
}
@media screen and (max-width: 600px) {
  #projectDetail .popup {
    .actions-wrapper {
      display: flex !important;
      flex-direction: column !important;
      .button {
        margin: 0 !important;
        margin-bottom: 13px !important;
        height: 40px !important;
        font-size: 11px !important;
      }
    }
  }
}
@media screen and (max-width: 466px) {
  #projectDetail {
    padding: 0 !important;
    .successes {
      padding: 5px !important;
    }
  }
}
